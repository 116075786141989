import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import _ from "lodash";
import Form from "react-bootstrap/Form";

const ArtistSetItem = props => {
  const set = props.set;
  const setId = props.setId;
  const updateDatabase = props.assets.updateDatabase;
  const artist = props.assets.artist;
  const artistId = props.assets.artistId;

  const [setlist, setSetlist] = React.useState(set);
  const [savedSetlist, setSavedSetlist] = React.useState(set);
  const [editMode, setEditMode] = React.useState(false);
  const [saveDisabled, setSaveDisabled] = React.useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    const cs = canSave(setlist, savedSetlist);
    if (!cs) {
      setSaveDisabled({
        disabled: true
      });
    } else {
      setSaveDisabled({});
    }
  }, [savedSetlist, setlist]);

  const canSave = (setlist, savedSetlist) => {
    const sl = { ...setlist };
    sl.name = sl && sl.name && sl.name.trim();
    if (sl.name === "") return false;
    return !_.isEqual(sl, savedSetlist);
  };

  const handleChange = e => {
    const newset = {
      ...setlist,
      [e.target.name]:
        e.target.name === "requestable" ? !setlist.requestable : e.target.value
    };
    setSetlist(newset);
  };

  const handleSave = () => {
    setSavedSetlist(setlist);
    setEditMode(false);
    updateDatabase("sets", setlist, setId);
  };

  const handleDelete = setId => {
    setEditMode(false);
    if (artist.currentSet.setId === setId) {
      updateDatabase("artists", { currentSet: {} }, artistId);
    }
    updateDatabase("sets", {}, setId);
  };

  const handleMakeRequestable = () => {
    const newSet = JSON.parse(JSON.stringify(set));
    newSet.requestable = !set.requestable;
    updateDatabase("sets", newSet, setId);
  };

  const setArtistCurrentSet = () => {
    updateDatabase(
      "artists",
      { currentSet: { name: setlist.name, setId } },
      artistId
    );
  };

  return (
    <>
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Setlist</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            This will delete the setlist only, not any of your tracks. This
            action cannot be undone.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDelete(setId);
              setShowDeleteConfirm(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ListGroup.Item className="d-flex justify-content-between">
        {editMode && (
          <div>
            <div className="d-flex mb-1">
              <Form.Control
                type="text"
                name="name"
                value={setlist.name}
                onChange={handleChange}
              />
            </div>
            <Button
              className="pt-0 pb-0 pl-1 pr-1 btn btn-light btn-outline-primary"
              {...saveDisabled}
              onClick={handleSave}
            >
              Update
            </Button>
            <Button
              className="pt-0 pb-0 pl-1 pr-1 ml-1 btn btn-light btn-outline-secondary"
              onClick={() => setEditMode(!editMode)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="pt-0 pb-0 pl-1 pr-1 ml-1"
              onClick={() => setShowDeleteConfirm(true)}
            >
              Delete
            </Button>
          </div>
        )}

        {!editMode && (
          <div style={{ width: "100%" }}>
            <Button
              className="text-primary list-group-item-button pb-0 mb-2"
              onClick={setArtistCurrentSet}
              style={{
                background: "none",
                border: "none",
                width: "100%",
                textAlign: "left"
              }}
            >
              <h5>
                {setlist.name}
                {setlist.requestable && " *"}
              </h5>
              {setlist.trackIds === undefined || setlist.trackIds.length === 0
                ? "No Tracks"
                : setlist.trackIds.length === 1
                ? "1 Track"
                : setlist.trackIds.length + " Tracks"}
            </Button>
            <div className="d-flex justify-content-between">
              <Form.Check
                type="switch"
                name="requestable"
                // checked={set && set.requestable}
                defaultChecked={set && set.requestable}
                id={`custom-switch-for-requestable-${setId}`}
                label="Requestable"
                // label=""
                onChange={handleMakeRequestable}
              />
              <Button
                className="pt-0 pb-0 ml-2"
                onClick={() => setEditMode(!editMode)}
              >
                Edit
              </Button>
            </div>
          </div>
        )}
        {!editMode && (
          <div className="ml-2">
            <div className="text-right"></div>
          </div>
        )}
      </ListGroup.Item>
    </>
  );
};

export default ArtistSetItem;
