import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import _ from "lodash";

const ArtistProfile = props => {
  const artist = props.artist[1];
  const artistId = props.assets.artistId;
  const updateDatabase = props.assets.updateDatabase;
  const isAdmin = props.assets.isAdmin;
  // const isArtist = props.assets.isArtist;
  const tracks = props.assets.tracks;

  const [profile, setProfile] = React.useState(artist);
  const [savedProfile, setSavedProfile] = React.useState(artist);
  // const [err, setErr] = React.useState();

  const handleSave = e => {
    e.preventDefault();
    setSavedProfile(profile);
    updateDatabase("artists", profile, artistId);
  };

  const handleProfileChange = e => {
    const newProfile = {
      ...profile,
      [e.target.name]: e.target.value,
    };
    setProfile(newProfile);
  };

  const handlePlayModePrefChange = value => {
    const newProfile = {
      ...profile,
      playModePref: value,
    };
    setProfile(newProfile);
    setSavedProfile(newProfile);
    updateDatabase("artists", newProfile, artistId);
  };

  const handleAllowNewRequests = () => {
    const newProfile = {
      ...profile,
      allowNewRequests: !profile.allowNewRequests,
    };
    if (newProfile.allowNewRequests === false) {
      newProfile.allowAudienceStyle = false;
    }
    setProfile(newProfile);
    setSavedProfile(newProfile);
    updateDatabase("artists", newProfile, artistId);
  };

  const handleAllowAudienceStyle = () => {
    const newProfile = {
      ...profile,
      allowAudienceStyle: !profile.allowAudienceStyle,
    };
    setProfile(newProfile);
    setSavedProfile(newProfile);
    updateDatabase("artists", newProfile, artistId);
  };

  const handleAllowPledges = () => {
    const newProfile = {
      ...profile,
      allowPledges: !profile.allowPledges,
    };
    setProfile(newProfile);
    setSavedProfile(newProfile);
    updateDatabase("artists", newProfile, artistId);
  };

  const handleShowInArtistList = () => {
    const newProfile = {
      ...profile,
      showInArtistList: !(profile.showInArtistList || false),
    };
    setProfile(newProfile);
    setSavedProfile(newProfile);
    updateDatabase("artists", newProfile, artistId);
  };

  const clearArtistPlayHistory = () => {
    const newArtist = {
      ...artist,
      playHistory: [],
    };
    updateDatabase("artists", newArtist, artistId);
  };

  return (
    <Form onSubmit={handleSave}>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Display Name
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="displayName"
            value={profile.displayName}
            onChange={handleProfileChange}
          />
        </Col>
      </Form.Group>
      {/* <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Description
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            name="description"
            rows="3"
            value={profile.description}
            onChange={handleProfileChange}
          />
        </Col>
      </Form.Group> */}
      <Form.Group as={Row}>
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}>
          <Button className="pt-0 pb-0 ml-2" onClick={clearArtistPlayHistory}>
            Clear Play History
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          PayPal Link
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="ppLink"
            value={profile.ppLink}
            onChange={handleProfileChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}>
          <Form.Label>
            <Form.Check
              type="switch"
              name="allowPledges"
              // defaultChecked={allowPledges}
              checked={profile.allowPledges}
              id={`custom-switch-for-allowPledges-${artistId}`}
              label="Allow Pledges"
              onChange={handleAllowPledges}
            />
          </Form.Label>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Youtube Streaming URL
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="urlStream"
            placeholder="https://youtu.be/???????????"
            value={profile.urlStream}
            onChange={handleProfileChange}
          />
        </Col>
      </Form.Group>
      {/* <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Facebook Live URL
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="text"
            name="urlFacebookLive"
            placeholder="https://????/???????????"
            value={profile.urlFacebookLive}
            onChange={handleProfileChange}
          />
        </Col>
      </Form.Group> */}
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Play Mode Preference
        </Form.Label>
        <Col sm={8}>
          <ToggleButtonGroup
            type="radio"
            name="playModePref"
            value={profile.playModePref}
            onChange={handlePlayModePrefChange}
          >
            <ToggleButton className="pt-0 pb-0" value="All">
              Both
            </ToggleButton>
            <ToggleButton className="pt-0 pb-0" value="Play">
              Play Mode Only
            </ToggleButton>
            <ToggleButton className="pt-0 pb-0" value="Requests">
              Requests Mode Only
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}>
          <Form.Label>
            <Form.Check
              type="switch"
              name="allowNewRequests"
              // defaultChecked={allowNewRequests}
              checked={profile.allowNewRequests}
              id={`custom-switch-for-allowNewRequests-${artistId}`}
              label="Allow New Requests"
              onChange={handleAllowNewRequests}
            />
          </Form.Label>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}>
          <Form.Label>
            <Form.Check
              {...{ disabled: !profile.allowNewRequests }}
              type="switch"
              name="allowAudienceStyle"
              // defaultChecked={allowAudienceStyle}
              checked={profile.allowAudienceStyle}
              id={`custom-switch-for-allowAudienceStyle-${artistId}`}
              label="Allow Audience To Enter New Style"
              onChange={handleAllowAudienceStyle}
            />
          </Form.Label>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}></Form.Label>
        <Col sm={8}>
          <Form.Label>
            <Form.Check
              type="switch"
              name="showInArtistList"
              // defaultChecked={showInArtistList}
              checked={profile.showInArtistList}
              id={`custom-switch-for-showInArtistList-${artistId}`}
              label="Show In Artist List"
              onChange={handleShowInArtistList}
            />
          </Form.Label>
        </Col>
      </Form.Group>
      {isAdmin && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              Track Export
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                name="trackExport"
                rows="3"
                value={
                  (tracks &&
                    tracks !== null &&
                    Object.values(tracks)
                      .filter(t => t !== null)
                      .map(t => `${t.title}\t${t.composer}\t${t.style}`)
                      .join("\n")) ||
                  ""
                }
                readOnly
              />
            </Col>
          </Form.Group>
        </>
      )}

      <Form.Group as={Row}>
        <Col>
          {!_.isEqual(profile, savedProfile) && (
            <Button className="pt-0 pb-0 mr-2" type="submit">
              Save
            </Button>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default ArtistProfile;
