import React from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

const Pledge = props => {
  const auth = props.assets.auth;
  const showPledge = props.assets.showPledge;
  const setShowPledge = props.assets.setShowPledge;
  const pledgeAmount = props.assets.pledgeAmount;
  const setPledgeAmount = props.assets.setPledgeAmount;
  const handlePledgeAmountChange = props.assets.handlePledgeAmountChange;
  const toggleRequest = props.assets.toggleRequest;
  const track = props.assets.track;

  return (
    <Modal show={showPledge} onHide={() => setShowPledge(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Contribution</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pledge-disclaimer-container">
          <div className="pledge-disclaimer">
            {/* <p>Only you and the artist will be able to see this dollar amount.</p> */}
            <p>
              Use the buttons below to increment your contribution to the
              artist.
            </p>
            <p>Your pledge amount is only visible to you and the artist.</p>
            <p>
              The more you pledge to contribute, the sooner your request may be
              played.
            </p>
            <p>Cancel anytime before your request is played.</p>
            <p>
              If you decide to change your contribution, simply cancel your
              request and make a new pledge.
            </p>
            <p>
              Please note the amount does not include any additional fees that
              may be collected to process any payments.
            </p>
            <p>
              Use the tip jar link to process your contributions by the end of
              the show.
            </p>
          </div>
        </div>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              Pledge Amount: ${pledgeAmount}
            </Form.Label>
            <Col sm={8}>
              <div className="d-flex justify-content-between m-2">
                {[1, 5, 10, 20].map(v => (
                  <Button
                    key={`tip-toggle-button-${v}`}
                    className=""
                    onClick={() => handlePledgeAmountChange(v)}
                  >
                    ${v}
                  </Button>
                ))}
              </div>
              <div className="d-flex justify-content-between m-2">
                {[50, 100].map(v => (
                  <Button
                    key={`tip-toggle-button-${v}`}
                    className=""
                    onClick={() => handlePledgeAmountChange(v)}
                  >
                    ${v}
                  </Button>
                ))}
                <Button
                  className="pt-0 pb-0"
                  onClick={() => setPledgeAmount(0)}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowPledge(false)}>Cancel</Button>
        <Button
          onClick={() => {
            setShowPledge(false);
            toggleRequest(track, auth);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Pledge;
