import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { fbConfig, rrfConfig } from "./config";

import configureStore from "./store";
import { SW_INIT, SW_UPDATE } from "./types";

const swState = {
  serviceWorkerInitialized: false,
  // serviceWorkerInitialized: true,
  serviceWorkerUpdated: false,
  // serviceWorkerUpdated: true,
  serviceWorkerRegistration: null
};

const initialState = window && {
  // ...initialStateForSW,
  swState,
  ...window.__INITIAL_STATE__
}; // set initial state here

const store = configureStore(initialState);

firebase.initializeApp(fbConfig);

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={rrfConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (reg) => store.dispatch({ type: SW_UPDATE, payload: reg })
});
