import moment from "moment";

export const sortAry = (aryOfObjs, key, order) => {
  let newAryOfObjs = aryOfObjs;
  newAryOfObjs &&
    newAryOfObjs.sort((a, b) => {
      const da = a[key];
      const db = b[key];
      let comparison = 0;
      if ((order || "asc") === "asc") {
        if (da > db) {
          comparison = 1;
        } else {
          comparison = -1;
        }
      } else {
        if (da < db) {
          comparison = 1;
        } else {
          comparison = -1;
        }
      }
      return comparison;
    });
  return newAryOfObjs;
};

export const tracksAry = (
  allTracks,
  sets,
  setlist,
  artist,
  pendingUpdates,
  auth
) => {
  return (
    (allTracks &&
      sortAry(
        Object.entries(allTracks)
          .filter(
            track =>
              track[1] !== null &&
              track[0] !== (artist.currentTrack && artist.currentTrack.trackId)
          )
          .map(
            track =>
              track &&
              track[1] && {
                ...track[1],
                trackId: track[0],
                requested: optRequested(track, pendingUpdates, auth),
                requestedPledge: optRequestedPledge(
                  track,
                  pendingUpdates,
                  auth
                ),
                requestedBy: optRequestedBy(track, pendingUpdates, auth),
                requestedLast: optRequestedLast(track, pendingUpdates, auth),
                requestedByHistory: sortRequestedByHistory(track),
                include:
                  setlist &&
                  setlist.trackIds &&
                  setlist.trackIds.indexOf(track[0]) >= 0
                    ? true
                    : false,
                sequence:
                  setlist && setlist.trackIds
                    ? setlist.trackIds.indexOf(track[0])
                    : -1,
                requestable: isRequestable(track[0], sets),
                sLastPlayed: getSortValue(track[1].lastPlayed),
                sRequestedLast: getSortValue(
                  optRequestedLast(track, pendingUpdates, auth),
                  optRequestedPledge(track, pendingUpdates, auth)
                ),
                includedSets: includedSets(track[0], sets)
              }
          ),
        "title",
        "asc"
      )) ||
    []
  );
};

const optRequestedPledge = (track, pendingUpdates, auth) => {
  const requestedBy = optRequestedBy(track, pendingUpdates, auth);
  return requestedBy.reduce((a, b) => Number(a) + Number(b.pledge), 0);
};

const optRequestedLast = (track, pendingUpdates, auth) => {
  const requestedBy = optRequestedBy(track, pendingUpdates, auth);
  const sortedRequestedBy = sortAry(requestedBy, "sRequested", "asc");
  return sortedRequestedBy[0] && sortedRequestedBy[0].requested;
};

const optRequested = (track, pendingUpdates, auth) => {
  if (optRequestedBy(track, pendingUpdates, auth).length > 0) {
    return true;
  }
  return false;
};

const optRequestedBy = (track, pendingUpdates, auth) => {
  const requestedBy =
    (track[1].requestedBy &&
      JSON.parse(JSON.stringify(track[1].requestedBy))) ||
    [];
  const pendingUpdatesAry = (pendingUpdates && pendingUpdates.split(",")) || [];
  for (const requestingTrack of pendingUpdatesAry) {
    const request = requestingTrack.split("-");
    const trackId = request[0];
    const action = request[1];
    const pledge = request[2] || 0;
    if (track[0] === trackId) {
      if (action === "remove") {
        if (requestedBy.map(r => r.uid).indexOf(auth.uid) >= 0) {
          requestedBy.splice(requestedBy.map(r => r.uid).indexOf(auth.uid), 1);
        }
      } else {
        if (requestedBy.map(r => r.uid).indexOf(auth.uid) < 0) {
          const d = new Date();
          requestedBy.push({
            uid: auth.uid,
            user: auth.displayName,
            photoURL: auth.photoURL,
            requested: d,
            pledge
          });
        }
      }
    }
  }

  return sortAry(
    requestedBy.map(rb => ({
      ...rb,
      sRequested: getSortValue(rb.requested)
    })),
    "sRequested",
    "desc"
  );
};

const sortRequestedByHistory = track => {
  const newRequestedByHistory =
    track[1].requestedByHistory &&
    track[1].requestedByHistory.map(rb => ({
      ...rb,
      sRequested: getSortValue(rb.requested)
    }));
  return sortAry(newRequestedByHistory, "sRequested", "desc");
};

const getSortValue = (obj, pledge) => {
  // const tip = ('000000000' + (p && p.toString())).slice(-9)
  const ts =
    obj &&
    obj.seconds &&
    obj.nanoseconds &&
    obj.seconds * 1000000000 + obj.nanoseconds;

  const pts = ("00000000000000000000" + ((ts && ts.toString()) || "")).slice(
    -20
  );
  const pd = 1000000000 - (pledge || 0);
  const pp = ("0000000000" + pd.toString()).slice(-10);

  return pp + pts;
};

const includedSets = (trackId, sets) => {
  const setsAry =
    sets &&
    Object.entries(sets || {})
      .filter(set => set[1] !== null)
      .map(s => ({ ...s[1], setId: s[0] }));
  const includedSets = (setsAry || []).filter(
    set => set.trackIds.indexOf(trackId) >= 0
  );
  const setNamesAry = includedSets.map(s => s.name);
  if (setNamesAry.length === 0) {
    return "(none)";
  }
  return setNamesAry.join(", ");
};

const isRequestable = (trackId, sets) => {
  const setsAry =
    (sets && Object.entries(sets).map(s => ({ ...s[1], setId: s[0] }))) || [];

  const requestableSets = setsAry.filter(set => set.requestable);

  for (const set of requestableSets) {
    if (set.trackIds.indexOf(trackId) >= 0) {
      return true;
    }
  }
  return false;
};

export const requestedTracks = (tracksAry, artist) =>
  (tracksAry &&
    artist &&
    sortAry(
      tracksAry.filter(track => track.requested === true),
      "sRequestedLast",
      "asc"
    )) ||
  [];

export const playOrderTracks = (
  tracksAry,
  setlist,
  artist,
  requestedTracks
) => {
  const tracks = [];
  for (const track of requestedTracks) {
    tracks.push(track);
  }
  const setlistTracks =
    (tracksAry && tracksAry.filter(track => track.include)) || [];
  const setlistTracksIncludeCurrent = [...setlistTracks, artist.currentTrack];
  const lastPlayedTracks =
    sortAry(
      setlistTracksIncludeCurrent.filter(track => track.lastPlayed),
      "sLastPlayed",
      "desc"
    ) || [];
  const nextSequenceIndex =
    (lastPlayedTracks[0] &&
      lastPlayedTracks[0].sequence !== setlistTracksIncludeCurrent.length - 1 &&
      lastPlayedTracks[0].sequence + 1) ||
    0;
  const canAddTrack = track => {
    if (artist.playHistory.indexOf(track.trackId) < 0) {
      if (
        requestedTracks.map(track => track.trackId).indexOf(track.trackId) < 0
      ) {
        return true;
      }
    }
    return false;
  };
  const sortedSetlistTracks = sortAry(setlistTracks, "sequence", "asc");
  for (const track of sortedSetlistTracks) {
    if (track.sequence >= nextSequenceIndex && canAddTrack(track)) {
      tracks.push(track);
    }
  }
  for (const track of sortedSetlistTracks) {
    if (track.sequence < nextSequenceIndex && canAddTrack(track)) {
      tracks.push(track);
    }
  }
  for (const track of sortedSetlistTracks) {
    if (tracks.map(track => track.trackId).indexOf(track.trackId) < 0)
      tracks.push(track);
  }

  return tracks;
};

export const requestableTracks = (requestedTracks, tracksAry) => {
  const tracks = requestedTracks;
  for (const track of tracksAry) {
    if (tracks.map(track => track.trackId).indexOf(track.trackId) < 0) {
      if (track.requestable) {
        tracks.push(track);
      }
    }
  }
  return tracks;
};

export const formatTimeStamp = ts => {
  let t = convertTSToDate(ts);
  return moment(t).calendar() + " (" + moment(t).fromNow() + ")";
};

export const convertTSToDate = ts => {
  let t;
  if (ts === undefined) {
    return ts;
  }
  if (ts instanceof Date) {
    t = ts;
  } else {
    try {
      t = ts.toDate();
    } catch {
      if (ts.seconds && ts.nanoseconds) {
        const v = ts.seconds * 1000 + Math.round(ts.nanoseconds / 1000000);
        t = new Date(v);
      }
    }
  }
  return t;
};
