import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ArtistTrack from "./ArtistTrack";
import Modal from "react-bootstrap/Modal";

const ArtistTrackItem = props => {
  const auth = props.assets.auth;
  const track = props.track;
  const artist = props.assets.artist;
  const artistId = props.assets.artistId;
  const handleIncludeTrack = props.assets.handleIncludeTrack;
  const setCurrentTrack = props.assets.setCurrentTrack;
  const updateDatabase = props.assets.updateDatabase;
  const isAdmin = props.assets.isAdmin;
  const isArtist = props.assets.isArtist;
  const isCurrentTrack = props.isCurrentTrack || false;
  const tracks = props.assets.tracks;

  const handleToggleRequest = props.assets.handleToggleRequest;
  const [showTrack, setShowTrack] = React.useState(false);
  const [
    showConfirmClearAllRequest,
    setShowConfirmClearAllRequests,
  ] = React.useState(false);

  const isArtistOrAdmin = (isArtist && auth.uid === artistId) || isAdmin;

  const handleClearAllRequests = () => {
    setShowConfirmClearAllRequests(true);
  };

  const clearAllRequests = track => {
    const newTrack = JSON.parse(JSON.stringify(tracks[track.trackId]));
    newTrack.requested = false;
    newTrack.requestedBy = [];
    delete newTrack.requestedLast;

    updateDatabase("tracks", newTrack, track.trackId, false);
  };

  const assets = {
    ...props.assets,
    showTrack,
    setShowTrack,
    track,
  };

  const TrackItem = (
    // <div style={{ color: "orange" }}>
    <div>
      <h5>
        {track.title}
        {isArtistOrAdmin && track.requested
          ? " $" + track.requestedPledge.toString()
          : ""}
      </h5>
      <div>
        {track.composer} - {track.style}
      </div>
      <Row>
        {track.requestedBy &&
          Array.isArray(track.requestedBy) &&
          track.requestedBy.map(request => (
            // <span key={request.uid} className="pr-2">
            <Col sm={4} key={request.uid}>
              <div className="user-request-item">
                <Image
                  src={request.photoURL}
                  alt=""
                  width="20"
                  height="20"
                  roundedCircle
                />
                {request.user || "Guest"}
                {(isArtistOrAdmin || auth.uid === request.uid) &&
                (request.pledge > 0 || artist.allowPledges)
                  ? " $" + request.pledge
                  : ""}
              </div>
            </Col>
            // </span>
          ))}
      </Row>
    </div>
  );

  return (
    <ListGroup.Item
      key={track.trackId}
      className={`d-flex justify-content-between text-decoration-none align-items-top${
        ((false || track.includedSets === "(none)") && " notIncluded") ||
        (track.requested && " requested") ||
        (artist.playHistory.indexOf(track.trackId) >= 0 && " played")
      }`}
    >
      {(isArtistOrAdmin && (
        <>
          <Button
            className="btn-light text-primary text-left"
            onClick={() => {
              setShowTrack(true);
            }}
            style={{
              background: "none",
              border: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            {TrackItem}
          </Button>
          <ArtistTrack track={track} assets={assets} />
        </>
      )) || (
        <div className="text-primary text-decoration-none">{TrackItem}</div>
      )}
      {/* <div className="ml-auto" style={{ color: "orange" }}> */}
      <div className="ml-2">
        <div className="text-right">
          {artist.playMode !== "Requests" && (isArtist || isAdmin) && (
            <div className="text-right mb-1">
              Sequence&nbsp;#{track.sequence + 1}
            </div>
          )}
          {isArtistOrAdmin &&
            (artist.playMode === "Edit" || track.includedSets === "(none)") && (
              <div className="text-right mb-1">
                <Form.Check
                  type="switch"
                  name="include"
                  // defaultChecked={track.include}
                  checked={track.include}
                  id={"custom-switch-for-" + track.trackId}
                  label="Include"
                  onChange={() => handleIncludeTrack(track.trackId)}
                />
              </div>
            )}
          {(isAdmin || !isArtist || (isArtist && auth.uid !== artistId)) &&
            !isCurrentTrack && (
              <div className="text-right">
                <Button
                  className="pt-0 pb-0 mb-1"
                  onClick={() => {
                    handleToggleRequest(track);
                  }}
                >
                  {track.requestedBy &&
                  track.requestedBy.map(r => r.uid).indexOf(auth.uid) >= 0
                    ? "Cancel"
                    : "Request"}
                </Button>
              </div>
            )}
        </div>

        {isArtistOrAdmin && !isCurrentTrack && artist.playMode !== "Edit" && (
          <>
            <div className="text-right">
              <Button
                className="pt-0 pb-0 mb-1"
                onClick={() => setCurrentTrack(track)}
              >
                Play
              </Button>
            </div>
            {track && track.requested && (
              <div className="text-right">
                <Button
                  className="pt-0 pb-0"
                  onClick={() => handleClearAllRequests(track)}
                >
                  Clear
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        show={showConfirmClearAllRequest}
        onHide={() => setShowConfirmClearAllRequests(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancel All Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This action will cancel all requests for this track and cannot be
            undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowConfirmClearAllRequests(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              clearAllRequests(track);
              setShowConfirmClearAllRequests(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </ListGroup.Item>
  );
};

export default ArtistTrackItem;
