import React from "react";
import Navigation from "./Navigation";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import Alert from "react-bootstrap/Alert";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import ArtistListItem from "./ArtistListItem";
import ArtistList from "./ArtistList";
import _ from "lodash";
import ArtistSet from "./ArtistSet";

const Artists = props => {
  const artistId = props.match.params.artistId;
  const profile = useSelector(state => state.firebase.profile);
  const auth = useSelector(state => state.firebase.auth);

  const isAdmin =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.admin &&
      true) ||
    false;

  const isArtist =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.artist &&
      true) ||
    false;

  const initQueries = [
    {
      collection: "artists",
      where: [["displayName", ">=", ""]],
    },
  ];

  const [queries, setQueries] = React.useState(initQueries);

  useFirestoreConnect(() => queries);

  const artists = useSelector(({ firestore: { data } }) => data.artists);

  React.useEffect(() => {
    const newQueries = [];
    if (artists && artistId) {
      newQueries.push({
        collection: "artists",
        where: [["displayName", ">=", ""]],
      });
      if (
        (isArtist && artistId === auth.uid) ||
        isAdmin ||
        artists[artistId].playMode !== "Edit"
      ) {
        newQueries.push({
          collection: "sets",
          where: [["ownerId", "==", artistId]],
        });
        newQueries.push({
          collection: "tracks",
          where: [["ownerId", "==", artistId]],
        });
      }
      setQueries(newQueries);
    }
  }, [artistId, artists, auth.uid, isAdmin, isArtist]);

  const sets = useSelector(({ firestore: { data } }) => data.sets);
  const tracks = useSelector(({ firestore: { data } }) => data.tracks);
  const artist = artists && artistId && artists[artistId];

  const [err, setErr] = React.useState();
  const [pendingUpdates, setPendingUpdates] = React.useState();

  React.useEffect(() => {
    // setPendingUpdates();
    const pendingUpdatesAry =
      (pendingUpdates && pendingUpdates.split(",")) || [];
    for (const requestingTrack of pendingUpdatesAry) {
      const request = requestingTrack.split("-");
      const trackId = request[0];
      const action = request[1];

      if (action === "remove") {
        if (
          (Array.isArray(tracks[trackId].requestedBy) &&
            tracks[trackId].requestedBy.map(r => r.uid).indexOf(auth.uid) <
              0) ||
          tracks[trackId].requestedBy === undefined
        ) {
          pendingUpdatesAry.splice(
            pendingUpdatesAry.indexOf(requestingTrack),
            1
          );
        }
      } else {
        if (
          Array.isArray(tracks[trackId].requestedBy) &&
          tracks[trackId].requestedBy.map(r => r.uid).indexOf(auth.uid) >= 0
        ) {
          pendingUpdatesAry.splice(
            pendingUpdatesAry.indexOf(requestingTrack),
            1
          );
        }
      }
    }
    setPendingUpdates(pendingUpdatesAry.join(","));
  }, [auth.uid, pendingUpdates, tracks]);

  const ufirestore = useFirestore();

  const updateDatabase = async (collection, updates, doc, merge) => {
    const cleanUpdates = removeEmpty(updates);
    if (doc) {
      if (_.isEqual(cleanUpdates, {})) {
        await ufirestore.delete(`${collection}/${doc}`);
      } else {
        await ufirestore
          .collection(collection)
          .doc(doc)
          .set(cleanUpdates, { merge: (merge === undefined && true) || merge })
          .catch(err => {
            console.error(`Error updating ${collection} collection:`, err);
            console.log(
              "collection:",
              collection,
              "doc:",
              doc,
              "cleanUpdates:",
              cleanUpdates
            );
            setErr(`Error updating ${collection} collection: ${err.message}`);
          });
      }
    } else {
      await ufirestore.collection(collection).add(cleanUpdates);
    }
  };

  const removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      else if (obj[key] === undefined) delete obj[key];
    });
    return obj;
  };

  const addArtist = () => {
    if (
      profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.artist &&
      auth.uid &&
      ((artists && !artists[auth.uid]) || artists === null)
    ) {
      updateDatabase(
        "artists",
        {
          displayName: auth.displayName,
          email: auth.email,
          playMode: "Edit",
          playModePref: "Requests",
          showInArtistList: true,
          photoURL: auth.photoURL,
          playHistory: [],
          ppLink: profile.PayPalLink || "",
          pledges: [],
          pledgesReconciled: [],
          urlStream: "",
          currentTrack: {},
          allowPledges: false,
          allowNewRequests: true,
          allowAudienceStyle: true,
        },
        auth.uid
      );
    }
  };

  addArtist();

  const assets = {
    auth,
    profile,
    isArtist,
    isAdmin,
    artists,
    sets,
    tracks,
    artist,
    artistId,
    setErr,
    pendingUpdates,
    setPendingUpdates,
    updateDatabase,
    target: "artists",
  };

  if (!isLoaded(artists) || !isLoaded(auth) || !isLoaded(profile)) {
    return <Spinner />;
  }

  // if (artistId && (!isLoaded(sets) || !isLoaded(tracks))) {
  //   return <Spinner />;
  // }

  return (
    <div>
      <Navigation
        back={!artistId ? "/" : "/artists"}
        title={(artistId && "Tracks") || "Choose Artist"}
        assets={{ ...assets, target: (artistId && "#") || assets.target }}
      />
      {err !== undefined && (
        <Alert variant="danger" onClose={() => setErr()} dismissible>
          {err}
        </Alert>
      )}
      <ListGroup>
        {artistId && (
          <>
            <ArtistListItem
              artist={[artistId, artist]}
              assets={{ ...assets, target: "#" }}
            />
            {isLoaded(sets) && isLoaded(tracks) && (
              <ArtistSet assets={assets} />
            )}
          </>
        )}
        {!artistId && <ArtistList assets={assets} />}
      </ListGroup>
    </div>
  );
};

export default Artists;
