import React from "react";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import ListGroup from "react-bootstrap/ListGroup";
import ArtistTracks from "./ArtistTracks";

const ArtistSetLayout = props => {
  const auth = props.assets.auth;
  const artistId = props.assets.artistId;
  const artist = props.assets.artist;
  const playOrderTracks = props.assets.playOrderTracks;
  const requestableTracks = props.assets.requestableTracks;
  const handleModeChange = props.assets.handleModeChange;
  const loadNextTrack = props.assets.loadNextTrack;
  const isAdmin = props.assets.isAdmin;
  const isArtist = props.assets.isArtist;
  const pledgeTotal = props.assets.pledgeTotal;
  const commitedTotal = props.assets.commitedTotal;

  const isArtistOrAdmin = (isArtist && auth.uid === artistId) || isAdmin;

  const assets = {
    ...props.assets
  };

  return (
    <>
      {(pledgeTotal > 0 || commitedTotal > 0) && (
        <ListGroup.Item className="d-flex justify-content-between">
          {/* {isArtistOrAdmin || auth.uid === request.uid
              ? " $" + request.pledge
            : ""} */}

          <div>Pledged: ${pledgeTotal + commitedTotal}</div>
          <div>Commited: ${commitedTotal}</div>
        </ListGroup.Item>
      )}
      {isArtistOrAdmin && (
        <>
          <ListGroup.Item className="d-flex justify-content-between">
            <div>
              <ToggleButtonGroup
                type="radio"
                name="playMode"
                value={artist.playMode}
                onChange={handleModeChange}
              >
                <ToggleButton className="pt-0 pb-0" value="Edit">
                  Edit
                </ToggleButton>
                {(artist.playModePref === "All" ||
                  artist.playModePref === "Play") && (
                  <ToggleButton className="pt-0 pb-0" value="Play">
                    Play
                  </ToggleButton>
                )}
                {(artist.playModePref === "All" ||
                  artist.playModePref === "Requests") && (
                  <ToggleButton className="pt-0 pb-0" value="Requests">
                    {artist.playModePref === "Requests" ? "Play" : "Requests"}
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </div>
            <div>
              {artist &&
                ((artist.playMode === "Play" && playOrderTracks.length > 0) ||
                  (artist.playMode === "Requests" &&
                    requestableTracks.length > 0)) && (
                  <Button className="pt-0 pb-0 ml-2" onClick={loadNextTrack}>
                    {_.isEqual(artist.currentTrack, {}) ? "Play" : "Next"}
                  </Button>
                )}
            </div>
          </ListGroup.Item>
        </>
      )}
      <ArtistTracks assets={assets} />
    </>
  );
};

export default ArtistSetLayout;
