import React from "react";
// import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useFirebase, isLoaded } from "react-redux-firebase";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import Alert from "react-bootstrap/Alert";
// import _ from "lodash";
// import UserProfile from "./UserProfile"
import Spinner from "./Spinner";

const Login = props => {
  const showLogin = props.assets.showLogin;
  const setShowLogin = props.assets.setShowLogin;
  const loginSuccess = props.assets.loginSuccess;
  const loginTitle = props.assets.loginTitle;
  // const [err, setErr] = React.useState();

  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);
  const appSettings = useSelector(
    ({ firestore: { data } }) => data.appSettings
  );

  const fbLoginEnabled = (
    (appSettings && Object.values(appSettings)) || [
      { name: "Enable Facebook Login", value: false },
    ]
  ).filter(s => s.name === "Enable Facebook Login")[0].value;

  // console.log(fbLoginEnabled);

  const signInOptions = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID
    // {
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    //   forceSameDevice: false,
    //   requireDisplayName: true
    // },
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ];

  if (fbLoginEnabled) {
    signInOptions.push(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
  }

  // const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
  // const [loginVerification, setLoginVerification] = React.useState();
  // const [verifiedEmail, setVerifedEmail] = React.useState("");
  // const [displayName, setDisplayName] = React.useState("");

  const sss = authResult => {
    firebase.handleRedirectResult(authResult).then(() => {
      setShowLogin(false);
      if (loginSuccess) {
        loginSuccess(authResult);
      } else {
        history.replace(from);
      }
      // props.history.push(initPath); // if you use react router to redirect
      // history.replace(from);
      // histor(y.replace(altFrom);
      // history.goBack();
      // console.log("i wanna go to this page:", from);
      // console.log("history:", history);
    });
    return false;
  };

  const uiConfig = {
    signInFlow: "popup",
    signInOptions,
    callbacks: {
      signInSuccessWithAuthResult: sss,
      // signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      //   console.log("redirectUrl", redirectUrl);
      //   firebase.handleRedirectResult(authResult).then(() => {
      //     // props.history.push(initPath); // if you use react router to redirect
      //     history.replace(from);
      //     // histor(y.replace(altFrom);
      //     // history.goBack();

      //     console.log("i wanna go to this page:", from);
      //     console.log("history:", history);
      //   });
      //   return false;
      // }
    },
  };

  // React.useEffect(() => {

  // })

  // const ufirestore = useFirestore();

  // if (
  //   firebase.auth().isSignInWithEmailLink(window.location.href) &&
  //   loginVerification === undefined &&
  //   showVerifyEmail === false
  // ) {
  //   //   //   //   // https://reqasong-01.firebaseapp.com/__/auth/action?apiKey=AIzaSyD__N4D-l40ScHFyP837zPk8X4NjPJMO0c&mode=signIn&oobCode=T6ayBv50G2hzg-2iDSZdKvOgAc9j_NmtggznUQutWUoAAAFxFKmaKg&continueUrl=http://localhost:3000/?ui_sid%3DWGgUoSVwFUxkcx6fdGlvXdQYoHViYpDx%26ui_sd%3D0&lang=en
  //   //   //   //   // return <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
  //   console.log("isSignInWithEmailLink");
  //   setLoginVerification(window.location.href);

  //   setShowVerifyEmail(true);

  //   //   //   console.log("what's my email address???");
  //   //   //   console.log(firebase.auth());
  //   //   //   // console.log(ui);

  //   //   //   // firebase
  //   //   //   //   .auth()
  //   //   //   //   .signInWithEmailLink("michael.hartman@brinks.com", window.location.href);
  // }

  // const verifyEmailAddress = () => {
  //   setShowVerifyEmail(false);
  //   firebase
  //     .auth()
  //     .signInWithEmailLink(verifiedEmail, loginVerification)
  //     .then(() => {
  //       //update user profile with name
  //       const newUser = { displayName, email: verifiedEmail };
  //       updateDatabase("users", newUser, auth.uid);
  //     })
  //     .catch(err => {
  //       console.error(`Error signing in ${verifiedEmail}:`, err);
  //       setErr(`Error signing in ${verifiedEmail}: ${err.message}`);
  //     });
  // };

  // const handleVerifiedEmailChange = e => {
  //   setVerifedEmail(e.target.value);
  // };

  // const uiCallback = ui => {
  //   //   // ui.disableAutoSignIn()
  //   //   console.log("I don't know... redirect to a signInSuccess function?");
  //   // console.log("uiCallback = ui => ", ui);
  //     if (ui.isSignInWithEmailLink(window.location.href)) {
  //       ui.start('#firebaseui-auth-container', uiConfig);
  //   //     // sss(auth);
  //     }
  // };

  if (!isLoaded(auth)) return <Spinner />;
  //user already logged in?
  // if (!isEmpty(auth)) return <Redirect to="/" />;

  return (
    // <>
    // {err !== undefined && (
    //   <Alert variant="danger" onClose={() => setErr()} dismissible>
    //     {err}
    //   </Alert>
    // )}
    <Modal show={showLogin} onHide={() => setShowLogin(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{loginTitle || "Log In"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FirebaseAuth
          // id=""
          // uiCallback={uiCallback}
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => {
            setShowLogin(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
    // </>
  );
};

export default Login;
