import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";

const AppSetting = props => {
  const updateDatabase = props.assets.updateDatabase;

  const [edit, setEdit] = React.useState(false);
  const [setting, setSetting] = React.useState(props.setting);
  const [savedSetting, setSavedSetting] = React.useState(props.setting);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [saveDisabled, setSaveDisabled] = React.useState({});

  React.useEffect(() => {
    setSetting(props.setting);
    setSavedSetting(props.setting);
  }, [props.setting]);

  React.useEffect(() => {
    const cs = canSave(setting, savedSetting);
    if (!cs) {
      setSaveDisabled({
        disabled: true
      });
    } else {
      setSaveDisabled({});
    }
  }, [savedSetting, setting]);

  const canSave = (setting, savedSetting) => {
    const sl = { ...setting };
    sl.name = sl && sl.name && sl.name.trim();
    if (sl.name === "") return false;
    return !_.isEqual(sl, savedSetting);
  };

  const handleChange = e => {
    const newSetting = { ...setting };
    newSetting[e.target.name] = e.target.value;
    setSetting(newSetting);
  };

  const handleDelete = settingId => {
    setEdit(false);
    updateDatabase("appSettings", {}, settingId);
  };

  const handleSave = () => {
    setSavedSetting(setting);
    setEdit(false);
    updateDatabase("appSettings", setting, props.settingId);
  };

  const handleSwitch = () => {
    // const newSetting = { ...setting };
    // newSetting.value = !newSetting.value;
    // console.log(newSetting);
    // updateDatabase("appSettings", newSetting, props.settingId);
    // console.log(setting, { ...setting, value: !setting.value });
    updateDatabase(
      "appSettings",
      { ...setting, value: !setting.value },
      props.settingId
    );
  };

  const handleSelect = e => {
    // console.log(e.target.value);
    updateDatabase(
      "appSettings",
      { ...setting, value: e.target.value },
      props.settingId
    );
  };

  return (
    <ListGroup.Item className="d-flex justify-content-between">
      {!edit && (
        <>
          <Button
            className="d-flex text-primary text-left align-items-center m-0 p-0 list-group-item-button"
            // className="btn btn-light btn-outline-primary"
            onClick={() => setEdit(true)}
            style={{
              background: "none",
              border: "none"
            }}
          >
            {setting.name || props.settingId}
          </Button>
          {setting.type === "switch" && (
            <Form.Check
              type="switch"
              name="value"
              checked={setting && setting.value}
              // defaultChecked={setting && setting.value}
              // label="Requestable"
              id={`custom-switch-for-value-${props.settingId}`}
              label=""
              onChange={handleSwitch}
            />
          )}
          {/* {setting.type !== "switch" && <div>{setting.value}</div>} */}
          {setting.type === "text" && <div>{setting.value}</div>}
          {setting.type !== "switch" && setting.type !== "text" && (
            <Form.Control
              as="select"
              name="value"
              value={setting.value}
              onChange={handleSelect}
              // className="dropdown-menu"
              className="w-auto"
            >
              {setting.type
                .split(",")
                .map(o => o.trim())
                .map(o => (
                  <option
                    // className="dropdown-item"
                    value={o}
                    key={o}
                  >
                    {o}
                  </option>
                ))}
            </Form.Control>
          )}
        </>
      )}
      {edit && (
        <>
          <div>
            <Form.Control
              type="text"
              name="name"
              className="mb-1"
              value={setting.name}
              onChange={handleChange}
            />
            <div>
              <Button
                className="pt-0 pb-0 pl-1 pr-1 btn btn-light btn-outline-primary"
                {...saveDisabled}
                onClick={handleSave}
              >
                Update
              </Button>
              <Button
                className="pt-0 pb-0 pl-1 pr-1 ml-1 btn btn-light btn-outline-secondary"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="pt-0 pb-0 pl-1 pr-1 ml-1"
                onClick={() => setShowDeleteConfirm(true)}
              >
                Delete
              </Button>
            </div>
          </div>
          <div>
            <Form.Control
              type="text"
              name="value"
              className="mb-1"
              value={setting.value}
              onChange={handleChange}
            />
            <Form.Control
              type="text"
              name="type"
              className="mb-1"
              value={setting.type}
              onChange={handleChange}
            />
          </div>
        </>
      )}
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Setlist</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            This will delete the setlist only, not any of your tracks. This
            action cannot be undone.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDelete(props.settingId);
              setShowDeleteConfirm(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </ListGroup.Item>
  );
};

export default AppSetting;
