/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import tpic from "./images/tip_jar_50x50.png";
// import tpic from "./images/tip_jar2-02-01.png";
import Image from "react-bootstrap/Image";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ArtistSets from "./ArtistSets";
import ArtistProfile from "./ArtistProfile";
import ArtistPledges from "./ArtistPledges";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ArtistListItem = props => {
  const artist = props.artist;
  const artistId = props.assets.artistId;
  const auth = props.assets.auth;
  const isArtist = props.assets.isArtist;
  const isAdmin = props.assets.isAdmin;
  const target =
    (props.assets && props.assets.target) === "#"
      ? "#"
      : `/${(props.assets && props.assets.target) || "request"}/${artist[0]}`;

  const [showSets, setShowSets] = React.useState(false);

  const assets = {
    ...props.assets,
    streamSrc: artist[1].urlStream,
  };

  const ArtistItem = (
    <div>
      <div className="d-flex">
        <div>
          <Image
            src={artist[1].photoURL || artist[1].photoUrl}
            alt="profile pic"
            width="50"
            roundedCircle
          />
        </div>
        {/* <div className="ml-2" style={{ color: "orange" }}> */}
        <div className="ml-2">
          <span>
            <div>
              <h5>{artist[1].displayName}</h5>
              <div>
                {artist[1].playMode === "Edit" &&
                !((isArtist && artistId === auth.uid) || isAdmin)
                  ? "(Currently Unavailable)"
                  : (artist[1].currentSet && artist[1].currentSet.name) ||
                    "(No Current Set Specified)"}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <ListGroup.Item className="text-decoration-none">
      <div className="d-flex justify-content-between">
        {target === "#" ? (
          <>
            {(isArtist && auth.uid === artistId) || isAdmin ? (
              <>
                <Button
                  className="d-flex text-primary text-left align-items-center m-0 p-0 list-group-item-button"
                  onClick={() => {
                    setShowSets(true);
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {ArtistItem}
                </Button>
                <Modal show={showSets} onHide={() => setShowSets(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <div>{artist[1].displayName}</div>
                      <h5>
                        {(artist[1].currentSet && artist[1].currentSet.name) ||
                          "(No Current Set Specified)"}
                      </h5>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Tabs
                      defaultActiveKey="profile"
                      id={`uncontrolled-tab-sets-${artist[0]}`}
                    >
                      <Tab eventKey="profile" title="Profile">
                        <ArtistProfile artist={artist} assets={assets} />
                      </Tab>
                      <Tab eventKey="sets" title="Sets">
                        <ArtistSets artist={artist} assets={assets} />
                      </Tab>
                      <Tab eventKey="pledges" title="Pledges">
                        <ArtistPledges artist={artist} assets={assets} />
                      </Tab>
                    </Tabs>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <div className="d-flex text-primary align-items-center">
                {ArtistItem}
              </div>
            )}
          </>
        ) : (
          <Link
            // to={"/request/" + artist[0]}
            to={target}
            className="d-flex text-decoration-none align-items-center"
          >
            {ArtistItem}
          </Link>
        )}
        {artist[1].ppLink && (
          <div className="d-flex text-decoration-none align-items-top">
            <div className="text-center">
              <a href={artist[1].ppLink} target="_blank">
                <Image src={tpic} alt="tipPic" width="50" />
              </a>
            </div>
          </div>
        )}
      </div>
    </ListGroup.Item>
  );
};

export default ArtistListItem;
