import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Spinner from "./Spinner";
import { useFirebase, useFirestore } from "react-redux-firebase";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector(state => state.firebase.auth);
  const firebase = useFirebase();
  const firestore = useFirestore();

  React.useEffect(() => {
    let anonSignInTimeOut;
    if (auth.isEmpty && auth.isLoaded) {
      anonSignInTimeOut = setTimeout(() => {
        console.log("creating anon account");
        true &&
          firebase
            .auth()
            .signInAnonymously()
            .then(returnedObject => {
              console.log("returnedObject:", returnedObject);
              const uid = returnedObject.user.uid;
              //add user to profiles.
              const newAnonUser = {
                displayName: "Guest",
                isAnon: true,
                avatarUrl:
                  "https://res.cloudinary.com/dgycwdnmx/image/upload/v1617314454/guest-account-icon-19_sfhaob.png",
              };
              firestore.collection("users").doc(uid).set(newAnonUser);
            })
            .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
              // ...
            });
      }, 500);
    }
    return () => {
      clearTimeout(anonSignInTimeOut);
    };
  }, [auth.isEmpty, auth.isLoaded, firebase, firestore]);

  if (!isLoaded(auth)) return <Spinner />;

  return (
    <Route
      {...rest}
      render={props =>
        !isEmpty(auth) ? <Component {...props} /> : <Spinner />
      }
    />
  );
};

export default ProtectedRoute;
