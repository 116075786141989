import React from "react";
// import { useFirebase } from "react-redux-firebase";
import ArtistListItem from "./ArtistListItem";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// import Button from "react-bootstrap/Button";

const ArtistList = props => {
  // const firebase = useFirebase();
  const artists = props.assets.artists;
  const auth = props.assets.auth;
  const isAdmin = props.assets.isAdmin;
  const isArtist = props.assets.isArtist;

  // const logout = () => {
  //   firebase.auth().signOut();
  // };

  const assets = {
    ...props.assets,
  };

  return (
    <>
      {/* <Tabs defaultActiveKey="artists" id={`uncontrolled-tab-sets-artists`}> */}
      {/* <Tab eventKey="artists" title="artists"> */}
      {artists &&
        Object.entries(artists)
          .filter(
            a =>
              a[1].showInArtistList === true ||
              isAdmin ||
              (isArtist && auth.uid === a[0])
          )
          .map(artist => (
            <ArtistListItem key={artist[0]} artist={artist} assets={assets} />
          ))}
      {/* </Tab> */}
      {/* <Tab eventKey="profile" title="Profile" className="text-center m-2"> */}
      {/* <Button className="btn btn-primary" onClick={logout}> */}
      {/* <NavLink to="/login" className="text-decoration-none"> */}
      {/* Logout */}
      {/* </NavLink> */}
      {/* </Button> */}
      {/* </Tab> */}
      {/* </Tabs> */}
    </>
  );
};

export default ArtistList;
