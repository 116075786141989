import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

const ArtistNewTrackLayout = props => {
  const auth = props.assets.auth;
  const isAdmin = props.assets.isAdmin;
  const showAddNew = props.assets.showAddNew;
  const setShowAddNew = props.assets.setShowAddNew;
  const artist = props.assets.artist;
  const isArtist = props.assets.isArtist;
  const artistId = props.assets.artistId;
  const trackTitle = props.assets.trackTitle;
  const search = props.assets.search;
  const handleSearchChange = props.assets.handleSearchChange;
  const handleSearch = props.assets.handleSearch;
  const showSearchResults = props.assets.showSearchResults;
  const searchResults = props.assets.searchResults;
  const loadSearchResult = props.assets.loadSearchResult;
  const track = props.assets.track;
  const handleChange = props.assets.handleChange;
  const allowAudienceStyle = artist.allowAudienceStyle;
  const handleSave = props.assets.handleSave;
  const saveDisabled = props.assets.saveDisabled;

  return (
    <Modal show={showAddNew} onHide={() => setShowAddNew(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{`${
          (isArtist && artistId === auth.uid) || isAdmin
            ? trackTitle
            : "New Request"
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              Search
            </Form.Label>
            <Col sm={8} className="d-flex">
              <Form.Control
                type="text"
                name="search"
                value={search}
                onChange={handleSearchChange}
              />
              <Button onClick={handleSearch}>Search</Button>
            </Col>
          </Form.Group>
          {showSearchResults && (
            <Form.Group as={Row}>
              <ListGroup>
                {searchResults.map((result, i) => {
                  return (
                    <ListGroup.Item
                      key={i}
                      className="d-flex justify-content-between text-decoration-none align-items-center"
                    >
                      <Button
                        className="d-flex text-primary text-left align-items-center m-0 p-0 list-group-item-button"
                        onClick={() => {
                          loadSearchResult(result.name, result.artistName);
                        }}
                        style={{
                          background: "none",
                          border: "none",
                          width: "100%",
                          textAlign: "left"
                        }}
                      >
                        <div className="ml-2">
                          <span>
                            <div>
                              <h5>{result.name}</h5>
                              <div>{result.artistName}</div>
                            </div>
                          </span>
                        </div>
                      </Button>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows="1"
                name="title"
                value={track && track.title}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              Artist
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows="1"
                name="composer"
                value={track && track.composer}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          {((isArtist && artistId === auth.uid) ||
            isAdmin ||
            allowAudienceStyle) && (
            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                Style
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="textarea"
                  rows="1"
                  name="style"
                  value={track && track.style}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              <Button
                className="pt-0 pb-0"
                {...saveDisabled}
                onClick={handleSave}
              >
                Save
              </Button>
            </Form.Label>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ArtistNewTrackLayout;
