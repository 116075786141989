import React from "react";

const Spinner = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ color: "orange" }}
      >
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Spinner;
