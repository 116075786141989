import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ArtistTrackItem from "./ArtistTrackItem";
import ArtistNewTrack from "./ArtistNewTrack";
import ArtistTrackSearch from "./ArtistTrackSearch";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import Login from "./Login";
import Pledge from "./Pledge";

const ArtistTracksLayout = props => {
  const auth = props.assets.auth;
  const artistId = props.assets.artistId;
  const artist = props.assets.artist;
  const isAdmin = props.assets.isAdmin;
  const isArtist = props.assets.isArtist;
  const filteredTracks = props.assets.filteredTracks;
  const clearCurrentTrack = props.assets.clearCurrentTrack;
  const markTrackAndClear = props.assets.markTrackAndClear;

  const assets = {
    ...props.assets
  };

  return (
    <>
      <Login assets={assets} />
      <Pledge assets={assets} />
      <ArtistNewTrack assets={assets} />
      <ArtistTrackSearch assets={assets} />
      {artist && !_.isEqual(artist.currentTrack, {}) && (
        <ListGroup.Item className="d-flex">
          <div className="text-center">
            <div className="mb-2">Current Song</div>
            {((isArtist && auth.uid === artistId) || isAdmin) && (
              <div>
                <Button className="pt-0 pb-0 mb-2" onClick={markTrackAndClear}>
                  Played
                </Button>
                <Button className="pt-0 pb-0" onClick={clearCurrentTrack}>
                  Clear
                </Button>
              </div>
            )}
          </div>
          <ListGroup className="ml-2" style={{ width: "100%" }}>
            <ArtistTrackItem
              key={artist.currentTrack.trackId}
              track={artist.currentTrack}
              assets={assets}
              isCurrentTrack={true}
            />
          </ListGroup>
        </ListGroup.Item>
      )}
      {filteredTracks &&
        filteredTracks.map(track => {
          return (
            <ArtistTrackItem
              key={track.trackId}
              track={track}
              assets={assets}
            />
          );
        })}
    </>
  );
};

export default ArtistTracksLayout;
