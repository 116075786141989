import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import banner from "./images/ras_logo_205.png";
import Login from "./Login";
import Button from "react-bootstrap/Button";
import { isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Home = () => {
  const auth = useSelector(state => state.firebase.auth);

  const [showLogin, setShowLogin] = React.useState(false);

  const loginSuccess = authResult => {};

  const assets = {
    showLogin,
    setShowLogin,
    loginSuccess,
  };

  return (
    <>
      <Login assets={assets} />
      <div className="p-2">
        <div className="text-center">
          <Image className="p-2" src={banner} alt="" />

          {/* <img src="./favicon-196.png" alt="" /> */}
          {/* <h1 className="p-4" style={{ color: "yellow" }}> */}
          <h1 className="p-3">Req-A-Song</h1>
        </div>
        <h4 className="p-2 text-center">Request a Song</h4>
        <div className="d-flex justify-content-center p-2">
          {!isEmpty(auth) ? (
            <div className="btn-xxl-container">
              <Link
                to="artists"
                // className="btn btn-primary btn-lg m-2 pt-0 pb-0"
                className="btn btn-primary btn-xxl"
                // style={{ background: "none", color:  }}
              >
                Artists
              </Link>
            </div>
          ) : (
            <>
              <div className="btn-xxl-container">
                <Button
                  className="btn btn-primary btn-xxl"
                  onClick={() => setShowLogin(true)}
                >
                  {/* <NavLink to="/login" className="text-decoration-none"> */}
                  Login
                  {/* </NavLink> */}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
