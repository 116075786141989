import React from "react";
import Table from "react-bootstrap/Table";
import { formatTimeStamp } from "./setlistUtils";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

const ArtistTrackTable = (props) => {
  const track = props.track;

  const requestEntry = (request) => {
    return (
      <ListGroup.Item
        key={`${request.uid}-${request.sRequested}`}
        className="d-flex align-items-center"
      >
        <Image src={request.photoURL} alt="" width="50" roundedCircle />
        <div className="ml-2">
          <div>{request.user}</div>
          <div>{request.email}</div>
          <div>{formatTimeStamp(request.requested)}</div>
        </div>
      </ListGroup.Item>
    );
  };

  return (
    <Table striped bordered>
      <tbody>
        <tr>
          <td>Included Sets</td>
          <td>{track.includedSets}</td>
        </tr>
        <tr>
          <td>Requested</td>
          <td>{track.requested ? "Currently Yes" : "Currently No"}</td>
        </tr>
        <tr>
          <td>Requested Last</td>
          <td>{formatTimeStamp(track.requestedLast)}</td>
        </tr>
        <tr>
          <td>Requested By:</td>
          <td>
            <ListGroup>
              {track.requestedBy &&
                Array.isArray(track.requestedBy) &&
                track.requestedBy.map((request) => requestEntry(request))}
            </ListGroup>
          </td>
        </tr>
        <tr>
          <td>Request History:</td>
          <td>
            {(track.requestedByHistory &&
              track.requestedByHistory.map((request) =>
                requestEntry(request)
              )) ||
              ""}
          </td>
        </tr>
        <tr>
          <td>Last Played/Skipped</td>
          <td>{formatTimeStamp(track.lastPlayed)}</td>
        </tr>
        <tr>
          <td>Play History:</td>
          <td>
            {(track &&
              track.playHistory &&
              track.playHistory.map((ph, i) => (
                <div key={track.id + "-" + i}>{formatTimeStamp(ph)}</div>
              ))) ||
              ""}
          </td>
        </tr>
        <tr>
          <td>Skip History:</td>
          <td>
            {(track &&
              track.skipHistory &&
              track.skipHistory.map((ph, i) => (
                <div key={track.id + "-" + i}>{formatTimeStamp(ph)}</div>
              ))) ||
              ""}
          </td>
        </tr>
        <tr>
          <td>Owner</td>
          <td>{track.owner}</td>
        </tr>
        <tr>
          <td>Owner Id</td>
          <td>{track.ownerId}</td>
        </tr>
        <tr>
          <td>Created At</td>
          <td>{formatTimeStamp(track.createdAt)}</td>
        </tr>
        <tr>
          <td>Last Updated</td>
          <td>{formatTimeStamp(track.lastUpdated)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ArtistTrackTable;
