import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { formatTimeStamp } from "./setlistUtils";

const ArtistPledges = props => {
  const artist = { ...props.artist[1], artistId: props.artist[0] };
  const tracks = props.assets.tracks;
  const updateDatabase = props.assets.updateDatabase;
  const artistId = props.assets.artistId;

  const handleReconcilePledge = p => {
    const newPledges = [...(artist.pledges || [])].filter(
      n =>
        n.uid !== p.uid ||
        n.trackId !== p.trackId ||
        n.sRequested !== p.sRequested
    );
    const newPledgesReconciled = [...(artist.newPledgesReconciled || []), p];
    const updates = {
      pledges: newPledges,
      pledgesReconciled: newPledgesReconciled
    };
    updateDatabase("artists", updates, artistId);
  };

  const handleReconcileAllForUser = uid => {
    const newPledges = [...(artist.pledges || [])].filter(n => n.uid !== uid);
    const newPledgesReconciled = [
      ...(artist.pledgesReconciled || []),
      ...(artist.pledges || []).filter(n => n.uid === uid)
    ];
    const updates = {
      pledges: newPledges,
      pledgesReconciled: newPledgesReconciled
    };
    updateDatabase("artists", updates, artistId);
  };

  if ((artist.pledges || []).length === 0) {
    return (
      <ListGroup>
        <ListGroup.Item className="d-flex justify-content-between">
          <div className="w-100">
            <div className="d-flex">
              <div className="ml-2">
                <h5>No Contributions to Reconcile</h5>
              </div>
            </div>
          </div>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  return (
    <ListGroup>
      {artist.pledges
        .filter((v, i, s) => s.map(s => s.uid).indexOf(v.uid) === i)
        .map(u => {
          return (
            <ListGroup.Item
              key={u.uid}
              className="d-flex justify-content-between"
            >
              <div className="w-100">
                <div className="d-flex">
                  <Image
                    src={u.photoURL}
                    alt="profile pic"
                    width="20"
                    height="20"
                    roundedCircle
                  />
                  <div className="ml-2">
                    <h5>
                      {u.user +
                        " $" +
                        artist.pledges
                          .filter(v => v.uid === u.uid)
                          .map(p => p.pledge)
                          .reduce((a, b) => a + b, 0)}
                    </h5>
                  </div>
                </div>
                <div>{u.email}</div>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                      <h5>Requests:</h5>
                    </Form.Label>
                    <Col sm={8}>
                      <ListGroup>
                        {artist.pledges
                          .filter(v => v.uid === u.uid)
                          .map((p, i) => (
                            <ListGroup.Item key={i}>
                              <div className="d-flex justify-content-between">
                                <div>
                                  {tracks[p.trackId].title} ${p.pledge}
                                </div>
                                <div>
                                  <Button
                                    className="pt-0 pb-0"
                                    onClick={() => {
                                      handleReconcilePledge(p);
                                    }}
                                  >
                                    Reconcile
                                  </Button>
                                </div>
                              </div>
                              <div>{formatTimeStamp(p.requested)}</div>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Col>
                  </Form.Group>
                </Form>
                <Button
                  onClick={() => {
                    handleReconcileAllForUser(u.uid);
                  }}
                >
                  Reconcile All
                </Button>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

export default ArtistPledges;
