import React from "react";
import * as utils from "./setlistUtils";
import ArtistTracksLayout from "./ArtistTracksLayout";
import { useSelector } from "react-redux";

const ArtistTracks = props => {
  const auth = props.assets.auth;
  const profile = useSelector(state => state.firebase.profile);
  const artist = props.assets.artist;
  const tracksAry = props.assets.tracksAry;
  const requestableTracks = props.assets.requestableTracks;
  const playOrderTracks = props.assets.playOrderTracks;
  const updateTrackIds = props.assets.updateTrackIds;
  const pendingUpdates = props.assets.pendingUpdates;
  const setPendingUpdates = props.assets.setPendingUpdates;
  const updateDatabase = props.assets.updateDatabase;

  const [showPledge, setShowPledge] = React.useState(false);
  const [pledgeAmount, setPledgeAmount] = React.useState(0);

  const defaultSearchCriteria = {
    criteria: "",
    // sorts: [{ field: "title", order: "asc" }]
    sorts: [],
  };
  //this is where tracks changes from the map of object to an array of objects with the additional/adjusted fields
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tracks =
    artist.playMode === "Edit"
      ? tracksAry
      : artist.playMode === "Requests"
      ? requestableTracks
      : artist.playMode === "Play"
      ? playOrderTracks
      : [];

  const [filteredTracks, setFilteredTracks] = React.useState(tracks);
  const [searchCriteria, setSearchCriteria] = React.useState(
    defaultSearchCriteria
  );
  const [searchOrderTitle, setSearchOrderTitle] = React.useState("");
  const [searchLabel, setSearchLabel] = React.useState("");
  const [showAddNew, setShowAddNew] = React.useState(false);
  const [pledgeTrack, setPledgeTrack] = React.useState();

  React.useEffect(() => {
    const newTracks = [];
    const modifier = searchCriteria.criteria.indexOf("||") >= 1 ? "OR" : "AND";
    const searchCrits = searchCriteria.criteria
      .toLowerCase()
      .replace(/&&/g, "||")
      .split("||");
    let keepIt = false;
    for (const track of tracks || []) {
      const flds = (track.title + track.style + track.composer).toLowerCase();
      for (const searchCrit of searchCrits) {
        const searchText = searchCrit.replace(/!!/g, "");
        const isThere = flds.indexOf(searchText) >= 0 ? true : false;
        const shouldBe = searchCrit.substr(0, 2) === "!!" ? false : true;
        keepIt = false;
        if ((isThere && shouldBe) || (!isThere && !shouldBe)) {
          keepIt = true;
          if (modifier === "OR") {
            break;
          }
        }
        if (modifier === "AND") {
          if (keepIt === false) {
            break;
          }
        }
      }
      keepIt && newTracks.push(track);
    }

    //sorts...
    for (let i = searchCriteria.sorts.length - 1; i >= 0; i--) {
      utils.sortAry(
        newTracks,
        searchCriteria.sorts[i].field,
        searchCriteria.sorts[i].order
      );
    }
    setFilteredTracks(newTracks);
    setSearchLabel(
      tracks &&
        (newTracks.length === tracks.length
          ? tracks.length + " Songs"
          : newTracks.length + "/" + tracks.length + " Songs")
    );
  }, [tracks, searchCriteria, searchOrderTitle]);

  const handleFilterChange = e => {
    setSearchCriteria({ ...searchCriteria, criteria: e.target.value });
  };

  const updateSearchCriteriaField = field => {
    const newSearchCriteria = searchCriteria;
    const newSorts = newSearchCriteria.sorts;
    const fldAry = newSorts.map(s => s.field);
    if (fldAry.indexOf(field) >= 0) {
      // if (newSorts[fldAry.indexOf(field)].order === "asc") {
      // newSorts[fldAry.indexOf(field)].order = "desc";
      // } else {
      newSorts.splice(fldAry.indexOf(field), 1);
      // }
    } else {
      // newSorts.push({ field, order: "asc" });
      newSorts.splice(0);
      newSorts.push({ field, order: "asc" });
    }
    newSearchCriteria.sorts =
      newSorts.length === 0 ? defaultSearchCriteria.sorts : newSorts;
    if (field === "clear") {
      newSearchCriteria.sorts = [];
    }

    setSearchCriteria(newSearchCriteria);

    const newSearchOrderTitle = newSearchCriteria.sorts
      .map(s => s.field)
      .join(",")
      .split(" ")
      .map(t => t.substring(0, 1).toUpperCase() + t.substring(1).toLowerCase())
      .join(" ");
    setSearchOrderTitle(newSearchOrderTitle);
  };

  const setAllFiltered = value => {
    updateTrackIds(value, filteredTracks);
  };

  const handleToggleRequest = track => {
    if (
      (!Array.isArray(track.requestedBy) ||
        track.requestedBy.map(r => r.uid).indexOf(auth.uid) < 0) &&
      artist.allowPledges
    ) {
      setPledgeTrack(track);
      setPledgeAmount(0);
      setShowPledge(true);
    } else {
      toggleRequest(track);
    }
  };

  const toggleRequest = track => {
    const requestingTrack = track || pledgeTrack;
    const d = new Date();

    const pendingUpdatesAry =
      (pendingUpdates && pendingUpdates.split(",")) || [];

    if (
      Array.isArray(requestingTrack.requestedBy) &&
      requestingTrack.requestedBy.map(r => r.uid).indexOf(auth.uid) >= 0
    ) {
      pendingUpdatesAry.push(requestingTrack.trackId + "-remove");
    } else {
      pendingUpdatesAry.push(requestingTrack.trackId + "-add-" + pledgeAmount);
    }

    setPendingUpdates(pendingUpdatesAry.join(","));

    const updates = {
      trackId: requestingTrack.trackId,
      requested: d,
      requestedBy: {
        uid: auth.uid,
        user: profile.displayName,
        email: profile.email,
        photoURL: profile.avatarUrl,
        requested: d,
        pledge: pledgeAmount,
      },
    };

    updateDatabase("requests", updates);
    // setTimeout(() => {
    //   updateDatabase("requests", updates);
    // }, 10000);
    setPledgeTrack();
  };

  const handlePledgeAmountChange = value => {
    setPledgeAmount(p => p + value);
  };

  // const loginSuccess = authResult => {
  //   toggleRequest(track, authResult.user);
  // };

  const assets = {
    ...props.assets,
    searchLabel,
    searchCriteria,
    setSearchCriteria,
    defaultSearchCriteria,
    searchOrderTitle,
    updateSearchCriteriaField,
    filteredTracks,
    handleFilterChange,
    setAllFiltered: updateTrackIds && setAllFiltered,
    showAddNew,
    setShowAddNew,
    handleToggleRequest,
    toggleRequest,
    showPledge,
    setShowPledge,
    pledgeAmount,
    setPledgeAmount,
    handlePledgeAmountChange,
  };

  return <ArtistTracksLayout assets={assets} />;
};

export default ArtistTracks;
