import React from "react";
import { useSelector } from "react-redux";
import { useFirebase, isEmpty, isLoaded } from "react-redux-firebase";
import { NavLink } from "react-router-dom";
import Spinner from "./Spinner";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Login from "./Login";
import AppSettings from "./AppSettings";
import { useHistory } from "react-router-dom";

const Navigation = props => {
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const history = useHistory();

  const [showLogin, setShowLogin] = React.useState(false);
  const [showAppSettings, setShowAppSettings] = React.useState(false);

  const logout = () => {
    history.push("/");
    setTimeout(() => {
      firebase.auth().signOut();
    }, 500);
  };

  const loginSuccess = authResult => {};

  const assets = {
    ...props.assets,
    showLogin,
    setShowLogin,
    loginSuccess,
    showAppSettings,
    setShowAppSettings,
  };

  const style = {
    // padding: "0.5em",
    height: "2em",
  };

  if (!isLoaded(auth)) return <Spinner />;

  return (
    <div style={style}>
      <div
        style={{
          position: "fixed",
          width: "100%",
          left: "0",
          top: "0",
          textAlign: "center",
          padding: "0.5em",
        }}
      >
        {props.title ? <span>{props.title}</span> : <span>&nbsp;</span>}
      </div>
      <div
        style={{
          position: "fixed",
          left: "0",
          top: "0",
          padding: "0.5em",
          zIndex: "1",
        }}
      >
        {props.back ? (
          <NavLink to={props.back} className="text-decoration-none">
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9.854 6.646a.5.5 0 010 .708L7.207 10l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                clipRule="evenodd"
              ></path>
              <path
                fillRule="evenodd"
                d="M6.5 10a.5.5 0 01.5-.5h6.5a.5.5 0 010 1H7a.5.5 0 01-.5-.5z"
                clipRule="evenodd"
              ></path>
            </svg>
          </NavLink>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
      <div
        style={{
          position: "fixed",
          right: "0",
          top: "0",
          padding: "0.5em",
          zIndex: "1",
        }}
      >
        {/* Profile */}
        {!isEmpty(auth) ? (
          <Dropdown alignRight>
            <Dropdown.Toggle className="p-0 m-0 border-0">
              <Image
                as={Dropdown.Toggle}
                src={profile.avatarUrl}
                alt=""
                width="20"
                roundedCircle
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {true && (
                <Dropdown.Item
                  as={Button}
                  onClick={() => {
                    setShowAppSettings(true);
                  }}
                >
                  Settings
                </Dropdown.Item>
              )}
              <Dropdown.Item as={Button} onClick={logout}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <>
            <Login assets={assets} />
            <Button className="pt-0 pb-0" onClick={() => setShowLogin(true)}>
              {/* <NavLink to="/login" className="text-decoration-none"> */}
              Login
              {/* </NavLink> */}
            </Button>
          </>
        )}
        {true && <AppSettings assets={assets} />}
      </div>
    </div>
  );
};

export default Navigation;
