import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Login from "./Login";
import _ from "lodash";

const ArtistTrackSearch = props => {
  const searchLabel = props.assets.searchLabel;
  const handleFilterChange = props.assets.handleFilterChange;
  const searchCriteria = props.assets.searchCriteria;
  const setSearchCriteria = props.assets.setSearchCriteria;
  const defaultSearchCriteria = props.assets.defaultSearchCriteria;
  const searchOrderTitle = props.assets.searchOrderTitle;
  const updateSearchCriteriaField = props.assets.updateSearchCriteriaField;
  const artist = props.assets.artist;
  const setAllFiltered = props.assets.setAllFiltered;
  const setShowAddNew = props.assets.setShowAddNew;
  const isArtist = props.assets.isArtist;
  const artistId = props.assets.artistId;
  const isAdmin = props.assets.isAdmin;
  const auth = props.assets.auth;
  const filteredTracks = props.assets.filteredTracks;

  const [showLogin, setShowLogin] = React.useState(false);

  const loginSuccess = authResult => {
    //this is gonna be a no-op because it's reloading...  nice try though...
    // setShowAddNew(true);
  };

  const assets = {
    ...props.assets,
    showLogin,
    loginSuccess,
    setShowLogin,
    loginTitle: "Log in to make a New Request"
  };

  return (
    <ListGroup.Item>
      {searchLabel}
      <InputGroup>
        <Form.Control
          type="text"
          id="filter"
          onChange={handleFilterChange}
          placeholder="Search..."
          value={searchCriteria.criteria}
        />
        <InputGroup.Append>
          <Button
            aria-label="Close"
            onClick={() => {
              updateSearchCriteriaField("clear");
              setSearchCriteria(defaultSearchCriteria);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </InputGroup.Append>
        {((isArtist && artistId === auth.uid) ||
          isAdmin ||
          //artist allows new requests
          artist.allowNewRequests) && (
          <InputGroup.Prepend>
            <Button
              aria-label="Add"
              className={
                filteredTracks &&
                filteredTracks.length === 0 &&
                !isArtist &&
                "hightlightButton"
              }
              onClick={() => {
                if (auth.isEmpty) {
                  setShowLogin(true);
                } else {
                  setShowAddNew(true);
                }
              }}
            >
              <span aria-hidden="true">+</span>
            </Button>
          </InputGroup.Prepend>
        )}
        <InputGroup.Append>
          <Dropdown alignRight>
            <Dropdown.Toggle title={searchOrderTitle}></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Button}
                onClick={() => updateSearchCriteriaField("title")}
              >
                Title
              </Dropdown.Item>
              <Dropdown.Item
                as={Button}
                onClick={() => updateSearchCriteriaField("composer")}
              >
                Composer
              </Dropdown.Item>
              <Dropdown.Item
                as={Button}
                onClick={() => updateSearchCriteriaField("style")}
              >
                Style
              </Dropdown.Item>
              {(artist.playMode === "Edit" || artist.playMode === "Play") && (
                <Dropdown.Item
                  as={Button}
                  onClick={() => updateSearchCriteriaField("sequence")}
                >
                  Sequence
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                as={Button}
                onClick={() => {
                  updateSearchCriteriaField("clear");
                  setSearchCriteria(defaultSearchCriteria);
                }}
              >
                (Clear)
              </Dropdown.Item>
              {artist.playMode === "Edit" &&
                (isAdmin || (isArtist && artistId === auth.uid)) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as={Button}
                      onClick={() => {
                        setAllFiltered(true);
                      }}
                      disabled={_.isEqual(artist.currentSet, {})}
                    >
                      Include All
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Button}
                      onClick={() => {
                        setAllFiltered(false);
                      }}
                      disabled={_.isEqual(artist.currentSet, {})}
                    >
                      Exclude All
                    </Dropdown.Item>
                  </>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup.Append>
      </InputGroup>
      <Login assets={assets} />
    </ListGroup.Item>
  );
};

export default ArtistTrackSearch;
