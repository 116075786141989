import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Artists from "./Artists";
import Spinner from "./Spinner";
import packageJson from "../package.json";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useFirestore } from "react-redux-firebase";
// import Toast from "react-bootstrap/Toast";
global.appVersion = packageJson.version;

const uiaInterval = 1 * 60 * 1000; //15 minutes

const App = () => {
  const isServiceWorkerInitialized = useSelector(
    state => state.swState.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    state => state.swState.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    state => state.swState.serviceWorkerRegistration
  );

  const [showSWInit, setShowSWInit] = React.useState(
    isServiceWorkerInitialized
  );
  const [showSWUpdated, setShowSWUpdated] = React.useState(
    isServiceWorkerUpdated
  );

  React.useEffect(() => {
    setShowSWInit(isServiceWorkerInitialized);
  }, [isServiceWorkerInitialized]);

  React.useEffect(() => {
    setShowSWUpdated(isServiceWorkerUpdated);
  }, [isServiceWorkerUpdated]);

  setTimeout(() => {
    setShowSWInit(false);
  }, 5000);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", e => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const firestore = useFirestore();

  React.useEffect(() => {
    const userIsActive = () => {
      const d = new Date();
      console.log("updating profile active: " + d.toISOString());
      firestore
        .collection("users")
        .doc(auth.uid)
        .update({ active: d.toISOString() });
    };
    if (auth.uid) {
      const uiaFunctionInterval = setInterval(userIsActive, uiaInterval);
      userIsActive();
      return () => {
        console.log("clearing user is active function");
        clearInterval(uiaFunctionInterval);
      };
    }
  }, [firestore, profile.userId, auth.uid]);

  return (
    <>
      {isServiceWorkerInitialized && (
        <div aria-live="polite" aria-atomic="true">
          <Alert
            show={showSWInit}
            onClose={() => setShowSWInit(false)}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 1000,
            }}
            variant="success"
            dismissible
          >
            Page has been saved for offline use
          </Alert>
        </div>
      )}
      {isServiceWorkerUpdated && (
        <div aria-live="polite" aria-atomic="true">
          <Alert
            show={showSWUpdated}
            onClose={() => setShowSWUpdated(false)}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 1000,
            }}
            variant="success"
            dismissible
            className="d-flex"
          >
            <div>There is a new version available.</div>
            <Button className="pt-0 pb-0 ml-2" onClick={updateServiceWorker}>
              Update
            </Button>
          </Alert>
        </div>
      )}

      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <ProtectedRoute exact path="/artists" component={Artists} />
          <ProtectedRoute exact path="/artists/:artistId" component={Artists} />
          <Route exact path="/spinner" component={Spinner} />
        </Switch>
      </Router>
      <div
        style={{
          position: "fixed",
          left: "0",
          bottom: "0",
          width: "100%",
          textAlign: "right",
        }}
      >
        <div
          style={{
            fontSize: ".6em",
          }}
        >
          v{global.appVersion}
        </div>
      </div>
    </>
  );
};

export default App;
