import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
import { getFirestore } from "redux-firestore";
import { getFirebase } from "react-redux-firebase";

export default function configureStore(initialState, history) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middleware)
  )(createStore);
  // const store = createStoreWithMiddleware(rootReducer);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducer", () => {
      const nextRootReducer = require("./reducer");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
