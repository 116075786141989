import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import ArtistSetItem from "./ArtistSetItem";
import * as utils from "./setlistUtils";

const ArtistSets = props => {
  const artist = props.artist;
  const updateDatabase = props.assets.updateDatabase;
  const artistId = props.assets.artistId;
  const sets = props.assets.sets;

  const setsAry = utils.sortAry(
    Object.entries(sets || {})
      .filter(s => s[1] !== null)
      .map(s => ({ setId: s[0], ...s[1] })),
    "name",
    "asc"
  );

  const addNewSetlist = () => {
    const updates = {
      name: "New Setlist",
      owner: artist[1].displayName,
      ownerId: artistId,
      requestable: true,
      trackIds: []
    };
    updateDatabase("sets", updates);
  };

  const assets = {
    ...props.assets
  };

  return (
    <>
      {/* <ListGroup> */}
      {/* <ListGroup.Item className="d-flex justify-content-between text-primary text-decoration-none"> */}
      <ListGroup.Item className="d-flex justify-content-between text-decoration-none">
        <Button
          className="d-flex text-primary text-left align-items-center m-0 p-0 list-group-item-button"
          // className="btn btn-light btn-outline-primary"
          onClick={() => addNewSetlist()}
          style={{
            background: "none",
            border: "none"
          }}
        >
          Add a New Setlist
        </Button>
        <div>+</div>
      </ListGroup.Item>
      {/* </ListGroup> */}
      {/* <ListGroup> */}
      {/* {sets &&
            Object.entries(sets).map(
              set =>
                set &&
                set[0] &&
                set[1] !== null && (
                  <ArtistSetItem
                    key={set[0]}
                    assets={assets}
                    set={set[1]}
                    setId={set[0]}
                  />
                )
            )} */}
      {sets &&
        setsAry &&
        setsAry.map(
          set =>
            set && (
              <ArtistSetItem
                key={set.setId}
                assets={assets}
                set={set}
                setId={set.setId}
              />
            )
        )}
      {/* </ListGroup> */}
    </>
  );
};

export default ArtistSets;
