import React from "react";
import Modal from "react-bootstrap/Modal";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AppSetting from "./AppSetting";
import _ from "lodash";

const AppSettings = props => {
  const initProfile = useSelector(state => ({
    displayName: state.firebase.profile.displayName,
  }));
  const auth = useSelector(state => state.firebase.auth);

  const isAdmin = props.assets.isAdmin;

  const [profile, setProfile] = React.useState(initProfile);
  const [savedProfile, setSavedProfile] = React.useState(initProfile);

  const showAppSettings = props.assets.showAppSettings;
  const setShowAppSettings = props.assets.setShowAppSettings;
  const setErr = props.assets.setErr;
  // const isAdmin = props.assets.isAdmin;
  const updateDatabase = props.assets.updateDatabase;

  const initQueries = [
    {
      collection: "users",
      // where: [["displayName", "==", ""]]
    },
    { collection: "appSettings" },
  ];

  // const [queries, setQueries] = React.useState(initQueries);

  // useFirestoreConnect(() => queries);
  useFirestoreConnect(() => initQueries);

  const users = useSelector(({ firestore: { data } }) => data.users);
  const appSettings = useSelector(
    ({ firestore: { data } }) => data.appSettings
  );

  React.useEffect(() => {
    const cv = "Current Version";
    if (
      appSettings &&
      Object.values(appSettings).filter(s => s.name === cv) &&
      Object.values(appSettings).filter(s => s.name === cv)[0] &&
      Object.values(appSettings).filter(s => s.name === cv)[0].value
    ) {
      // console.log(
      //   Object.values(appSettings).filter(s => s.name === cv)[0].value,
      //   global.appVersion
      // );
      if (
        Object.values(appSettings).filter(s => s.name === cv)[0].value !==
        global.appVersion
      ) {
        // setErr(
        //   "The current version of the application does not match the version that is currently running... You may need to clear your browser cache and reload the application."
        // );
      } else {
        setErr();
      }
    }
  }, [appSettings, setErr]);

  // React.useEffect(() => {
  //   const newQueries = [];
  //   if (showAppSettings && isAdmin) {
  //     newQueries.push({
  //       collection: "users",
  //       where: [["displayName", ">=", ""]]
  //     });
  //     newQueries.push({
  //       collection: "appSettings"
  //     });
  //     setQueries(newQueries);
  //   }
  // }, [isAdmin, showAppSettings]);

  const addSetting = () => {
    updateDatabase("appSettings", {
      name: "New Setting",
      type: "text",
      value: "New Value",
    });
  };

  const handleSave = e => {
    e.preventDefault();
    setSavedProfile(profile);
    updateDatabase("users", profile, auth.uid);
    console.log(profile);
    console.log(auth.uid);
  };

  const handleProfileChange = e => {
    const newProfile = {
      ...profile,
      [e.target.name]: e.target.value,
    };
    setProfile(newProfile);
  };

  return (
    <Modal show={showAppSettings} onHide={() => setShowAppSettings(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="userProfile"
          id={`uncontrolled-tab-admin-settings`}
        >
          <Tab eventKey="userProfile" title="Profile">
            <Form onSubmit={handleSave}>
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  Display Name
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="displayName"
                    value={profile.displayName}
                    onChange={handleProfileChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col>
                  <Button
                    className="pt-0 pb-0 mr-2"
                    type="submit"
                    disabled={_.isEqual(profile, savedProfile)}
                  >
                    Save
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Tab>
          {isAdmin && (
            <Tab eventKey="settings" title="App Settings">
              <ListGroup>
                {appSettings &&
                  Object.entries(appSettings).map(
                    setting =>
                      setting[1] && (
                        <AppSetting
                          key={setting[0]}
                          settingId={setting[0]}
                          setting={setting[1]}
                          assets={props.assets}
                        />
                      )
                  )}
                <ListGroup.Item className="d-flex justify-content-between">
                  <Button
                    className="d-flex text-primary text-left align-items-center m-0 p-0 list-group-item-button"
                    // className="btn btn-light btn-outline-primary"
                    onClick={addSetting}
                    style={{
                      background: "none",
                      border: "none",
                    }}
                  >
                    Add Setting
                  </Button>
                  <div>+</div>
                </ListGroup.Item>
              </ListGroup>
            </Tab>
          )}
          {isAdmin && (
            <Tab eventKey="users" title="Users">
              <ListGroup>
                {users &&
                  Object.entries(users).map(
                    user =>
                      user &&
                      user[1] && (
                        <ListGroup.Item
                          key={user[0]}
                          className="d-flex justify-content-between"
                        >
                          <div>
                            <div className="d-flex">
                              <Image
                                src={user[1].avatarUrl}
                                alt="profile pic"
                                width="20"
                                height="20"
                                roundedCircle
                              />
                              <div className="ml-2">
                                <h6>
                                  {user[1].displayName +
                                    (user[1].claims && user[1].claims.artist
                                      ? " - Artist"
                                      : "")}
                                </h6>
                              </div>
                            </div>
                            <div>{user[1].email}</div>
                            {/* </div>
                <div> */}
                            <Button
                              onClick={() => {
                                updateDatabase("subscribers", {
                                  email: user[1].email,
                                  role: "artist",
                                });
                              }}
                            >
                              Toggle Artist
                            </Button>
                          </div>
                        </ListGroup.Item>
                      )
                  )}
              </ListGroup>
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default AppSettings;
