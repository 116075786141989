import React from "react";
import { useFirestore } from "react-redux-firebase";
import ArtistNewTrackLayout from "./ArtistNewTrackLayout";
// import LastFM from 'last-fm'

const LastFM = require("last-fm");
const apiKey = "49d8cadbec1b8623b77e02a0d90398a8";
const lastfm = new LastFM(apiKey, {
  userAgent: `YLR/${global.appVersion} (https://your-live-requests.web.app)`
});

const ArtistNewTrack = props => {
  const auth = props.assets.auth;
  const isAdmin = props.assets.isAdmin;
  const isArtist = props.assets.isArtist;
  const artist = props.assets.artist;
  const artistId = props.assets.artistId;
  const setShowAddNew = props.assets.setShowAddNew;
  const setErr = props.assets.setErr;
  const handleIncludeTrack = props.assets.handleIncludeTrack;
  const searchCriteria = props.assets.searchCriteria;
  const allowAudienceStyle = artist.allowAudienceStyle;
  const handleToggleRequest = props.assets.handleToggleRequest;

  const [track, setTrack] = React.useState();
  const [saveDisabled, setSaveDisabled] = React.useState({});
  const [trackTitle, setTrackTitle] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [showSearchResults, setShowSearchResults] = React.useState(false);

  React.useEffect(() => {
    setSearch(searchCriteria.criteria);
    setSearchResults([]);
    setShowSearchResults(false);
  }, [searchCriteria]);

  React.useEffect(() => {
    const cs = canSave(track, artist, isArtist, isAdmin, artistId, auth.uid);
    if (!cs) {
      setSaveDisabled({
        disabled: true
      });
    } else {
      setSaveDisabled({});
    }
    setTrackTitle(titleLabel(track && track.title));
  }, [artist, auth.uid, isArtist, isAdmin, artistId, track]);

  const canSave = (initTrack, artist, isArtist, isAdmin, artistId, uid) => {
    if (!artist || !artist.currentSet || !artist.currentSet.setId) {
      return false;
    }
    const track = { ...initTrack };
    for (const f of ["title", "composer", "style"]) {
      track[f] = track && track[f] && track[f].trim();
    }

    if (track.title === "") {
      return false;
    }

    let t, c, s;
    if (track && track.title && track.title.indexOf("\n") >= 0) {
      t = track.title.split("\n");
    }
    if (track && track.composer && track.composer.indexOf("\n") >= 0) {
      c = track.composer.split("\n");
    }
    if (track && track.style && track.style.indexOf("\n") >= 0) {
      s = track.style.split("\n");
    }

    if ((t || c || s) && !isAdmin && !(isArtist && artistId === uid)) {
      return false;
    }

    if (t && c && t.length !== c.length) {
      return false;
    }
    if (t && s && t.length !== s.length) {
      return false;
    }
    if (s && c && s.length !== c.length) {
      return false;
    }

    return true;
  };

  const handleChange = e => {
    setTrack({ ...track, [e.target.name]: e.target.value });
  };

  const handleSearchChange = e => {
    setSearch(e.target.value);
    setSearchResults([]);
  };

  const handleSearch = () => {
    setShowSearchResults(true);
    if (searchResults.length === 0) {
      lastfm.trackSearch({ q: search }, (err, data) => {
        if (err) {
          console.error(err);
          setErr(err.description);
        } else {
          setSearchResults(data.result);
        }
      });
    }
  };

  const loadSearchResult = (track, artistName) => {
    setShowSearchResults(false);
    const newTrack = { title: track, composer: artistName };
    lastfm.trackTopTags(
      { name: track, artistName: artistName, limit: 1 },
      (err, data) => {
        if (err) {
          console.error("trackTopTags err:", err);
          setErr(err.description);
        } else {
          newTrack.style = (
            (data && data.tag && data.tag[0] && data.tag[0].name) ||
            ""
          )
            .split(" ")
            .map(word => word[0].toUpperCase() + word.slice(1))
            .join(" ");
          setTrack(newTrack);
        }
      }
    );
  };

  const titleLabel = t => {
    if (t && t.indexOf("\n") >= 0) {
      return "(Multiple)";
    } else {
      if ((t || "") === "") {
        return "(New Song)";
      } else {
        return t;
      }
    }
  };

  const ufirestore = useFirestore();

  const handleSave = () => {
    const newTrack = track;
    for (const fld of ["title", "composer", "style"]) {
      newTrack[fld] && (newTrack[fld] = newTrack[fld].trim());
    }
    const d = new Date();
    newTrack.createdAt = d;
    newTrack.owner = artist.displayName;
    newTrack.ownerId = artistId;
    if (!isAdmin && !(isArtist && artistId === auth.uid)) {
      //audience making a new request...
      if (!allowAudienceStyle) {
        newTrack.style = "New Request";
      }
    }
    ufirestore
      .collection("tracks")
      .add(newTrack)
      .then(docRef => {
        if (isAdmin || (isArtist && artistId === auth.uid)) {
          handleIncludeTrack(docRef.id);
        } else {
          handleToggleRequest({ trackId: docRef.id, requestedBy: [] });
        }
        setTrack();
        setSearch("");
        setSearchResults([]);
        setShowAddNew(false);
      })
      .catch(err => {
        console.info(
          `Error adding ${
            (isArtist && artistId === auth.uid) || isAdmin
              ? trackTitle
              : "New Request"
          }:`,
          err
        );
        setErr(err.message);
      });
  };

  const assets = {
    ...props.assets,
    trackTitle,
    search,
    handleSearchChange,
    handleSearch,
    showSearchResults,
    searchResults,
    loadSearchResult,
    track,
    handleChange,
    handleSave,
    saveDisabled
  };

  return <ArtistNewTrackLayout assets={assets} />;
};

export default ArtistNewTrack;
