import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArtistTrackTable from "./ArtistTrackTable";
import ProgressBar from "react-bootstrap/ProgressBar";

const ArtistTrackLayout = props => {
  const track = props.track;
  const handleChange = props.assets.handleChange;
  const handleSave = props.assets.handleSave;
  const saveDisabled = props.assets.saveDisabled;
  const setShowDeleteConfirm = props.assets.setShowDeleteConfirm;
  const uploadProgress = props.assets.uploadProgress;

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Title
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            rows="1"
            name="title"
            value={track.title}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Composer
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            rows="1"
            name="composer"
            value={track.composer}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Style
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            rows="1"
            name="style"
            value={track.style}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          <Button className="pt-0 pb-0" {...saveDisabled} onClick={handleSave}>
            Save
          </Button>
        </Form.Label>
        <Form.Label column sm={8}>
          {/* I think this is going to have to be a fixed width like this... */}
          {uploadProgress > 0 && <ProgressBar now={uploadProgress} />}
        </Form.Label>
      </Form.Group>
      <ArtistTrackTable track={track} assets={props.assets} />
      {setShowDeleteConfirm && (
        <Form.Group as={Row}>
          <Col>
            <Button
              className="btn btn-light btn-outline-danger mr-2"
              onClick={() => setShowDeleteConfirm(true)}
            >
              Delete
            </Button>
          </Col>
        </Form.Group>
      )}
    </Form>
  );
};

export default ArtistTrackLayout;
