// const sharedSecret = "8702cbdf962febde1bbf1651e0e439b8"
// const registeredTo = "hartman42a"
// const lastfm = new LastFM(apiKey, { userAgent: 'MyApp/1.0.0 (https://reqasong-01.web.app)' })

export const fbConfig = {
  apiKey: "AIzaSyB7WZ-7zDLoXGw58__6x_cM3NNJNT9QIMY",
  authDomain: "reqasong-01.firebaseapp.com",
  databaseURL: "https://reqasong-01.firebaseio.com",
  projectId: "reqasong-01",
  storageBucket: "reqasong-01.appspot.com",
  messagingSenderId: "370502767133",
  appId: "1:370502767133:web:f7a4d17c02585bca64acf4",
  measurementId: "G-7HG72X5TJ7",
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  enableClaims: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { fbConfig, rrfConfig };
