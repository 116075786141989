import React from "react";
import ArtistTrackLayout from "./ArtistTrackLayout";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import _ from "lodash";

const ArtistTrack = props => {
  const initTrack = props.track;
  const showTrack = props.assets.showTrack;
  const setShowTrack = props.assets.setShowTrack;
  const sets = props.assets.sets;
  const updateDatabase = props.assets.updateDatabase;
  const tracks = props.assets.tracks;

  const [track, setTrack] = React.useState(initTrack);
  const [savedTrack, setSavedTrack] = React.useState(initTrack);
  const [trackTitle, setTrackTitle] = React.useState("");
  const [saveDisabled, setSaveDisabled] = React.useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    setTrack(initTrack);
    setSavedTrack(initTrack);
  }, [initTrack]);

  React.useEffect(() => {
    const cs = canSave(track, savedTrack);
    if (!cs) {
      setSaveDisabled({
        disabled: true
      });
    } else {
      setSaveDisabled({});
    }
    setTrackTitle(titleLabel(track));
  }, [savedTrack, track]);

  const canSave = (initTrack, savedTrack) => {
    const track = { ...initTrack };
    for (const f of ["title", "composer", "style"]) {
      track[f] = track && track[f] && track[f].trim();
    }

    if (!_.isEqual(track, savedTrack) && track.title !== "") {
      return true;
    }
    return false;
  };

  const titleLabel = t => {
    return (t && t.title && t.title !== "" && t.title) || "(Invalid)";
  };

  const handleChange = e => {
    setTrack({ ...track, [e.target.name]: e.target.value });
  };

  const handleDeleteTrack = () => {
    const deleteTrackId = initTrack.trackId;
    //should search through all sets for the trackId
    const setLists = Object.entries(sets)
      .filter(s => s[1] !== null)
      .map(s => ({
        ...s[1],
        setId: s[0]
      }));
    for (const set of setLists) {
      if (set.trackIds.indexOf(deleteTrackId) >= 0) {
        const trackIds = [...sets[set.setId].trackIds];
        trackIds.splice(trackIds.indexOf(deleteTrackId), 1);
        updateDatabase("sets", { ...sets[set.setId], trackIds }, set.setId);
      }
    }
    updateDatabase("tracks", {}, deleteTrackId);
    setShowTrack(false);
  };

  const handleSave = () => {
    const updates = JSON.parse(JSON.stringify(tracks[track.trackId]));
    const newTrack = { ...track };
    updates.lastUpdated = new Date();
    newTrack.lastUpdated = updates.lastUpdated;
    for (const f of ["title", "composer", "style"]) {
      updates[f] = track[f].trim();
      newTrack[f] = track[f].trim();
    }
    setTrack(newTrack);
    setSavedTrack(newTrack);

    updateDatabase("tracks", updates, track.trackId);
    setShowTrack(false);
  };

  const assets = {
    ...props.assets,
    saveDisabled,
    handleChange,
    handleSave,
    setShowDeleteConfirm,
    showDeleteConfirm
  };

  return (
    <Modal show={showTrack} onHide={() => setShowTrack(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{`${trackTitle}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ArtistTrackLayout track={track} assets={assets} />
        <Modal
          show={showDeleteConfirm}
          onHide={() => setShowDeleteConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Track</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>This action cannot be undone.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteTrack();
                setShowDeleteConfirm(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setShowTrack(false);
          }}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArtistTrack;
