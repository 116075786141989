import React from "react";
import ArtistSetLayout from "./ArtistSetLayout";
import * as utils from "./setlistUtils";
import _ from "lodash";

const ArtistSet = props => {
  const auth = props.assets.auth;
  const tracks = props.assets.tracks;
  const sets = props.assets.sets;
  const artist = props.assets.artist;
  const artistId = props.assets.artistId;
  const setId = artist.currentSet && artist.currentSet.setId;
  const set = setId && sets && sets[setId];
  const pendingUpdates = props.assets.pendingUpdates;
  const updateDatabase = props.assets.updateDatabase;
  const isArtist = props.assets.isArtist;
  const isAdmin = props.assets.isAdmin;
  const isArtistOrAdmin = (isArtist && auth.uid === artistId) || isAdmin;

  const tracksAry = utils.tracksAry(
    tracks,
    sets,
    set,
    artist,
    pendingUpdates,
    auth
  );
  const requestedTracks = utils.requestedTracks(tracksAry, artist);
  const playOrderTracks = utils.playOrderTracks(
    tracksAry,
    set,
    artist,
    requestedTracks
  );
  const requestableTracks = utils.requestableTracks(requestedTracks, tracksAry);

  const handleModeChange = value => {
    const newArtist = { ...artist, playMode: value };
    updateDatabase("artists", newArtist, artistId);
  };

  const loadNextTrack = () => {
    const newArtist = JSON.parse(JSON.stringify(artist));
    const lastPlayed = new Date();
    if (!_.isEqual(artist.currentTrack, {})) {
      // const newArtist = { ...artist };
      // const requestedBy = [...(newTrack.requestedBy || [])];
      // const newPledges = [
      //   ...(newArtist.pledges || []),
      //   ...requestedBy.map(rb => ({ ...rb, trackId }))
      // ];
      // newArtist.pledges = newPledges;
      // updateDatabase("artists", newArtist, artistId);

      newArtist.pledges = [
        ...(newArtist.pledges || []),
        ...(artist.currentTrack.requestedBy || []).map(rb => ({
          ...rb,
          trackId: artist.currentTrack.trackId
        }))
      ];

      markTrackPlayed(artist.currentTrack.trackId, lastPlayed);
      newArtist.playHistory.push(artist.currentTrack.trackId);
    }
    newArtist.currentTrack = playOrderTracks[0];
    updateDatabase("artists", newArtist, artistId);
  };

  const markTrackAndClear = () => {
    const newArtist = JSON.parse(JSON.stringify(artist));
    const lastPlayed = new Date();
    if (!_.isEqual(artist.currentTrack, {})) {
      newArtist.pledges = [
        ...(newArtist.pledges || []),
        ...(artist.currentTrack.requestedBy || []).map(rb => ({
          ...rb,
          trackId: artist.currentTrack.trackId
        }))
      ];

      markTrackPlayed(artist.currentTrack.trackId, lastPlayed);
      newArtist.playHistory.push(artist.currentTrack.trackId);
    }
    newArtist.currentTrack = {};
    updateDatabase("artists", newArtist, artistId);
  };

  const setCurrentTrack = track => {
    const newArtist = JSON.parse(JSON.stringify(artist));
    newArtist.currentTrack = track;
    updateDatabase("artists", newArtist, artistId);
  };

  const markTrackPlayed = (trackId, lastPlayed) => {
    // const newTrack = JSON.parse(JSON.stringify(tracks[trackId]));
    const newTrack = { ...tracks[trackId] };

    newTrack.lastPlayed = lastPlayed;

    const playHistory = [...(newTrack.playHistory || [])];
    playHistory.push(newTrack.lastPlayed);
    newTrack.playHistory = playHistory;

    newTrack.requested = false;

    const requestedByHistory = [
      ...(newTrack.requestedByHistory || []),
      ...(newTrack.requestedBy || [])
    ];
    newTrack.requestedByHistory = requestedByHistory;

    newTrack.requestedBy = [];

    updateDatabase("tracks", newTrack, trackId);
  };

  const clearCurrentTrack = () => {
    const newArtist = { ...artist, currentTrack: {} };
    updateDatabase("artists", newArtist, artistId);
  };

  const handleIncludeTrack = trackId => {
    const setlist = { ...sets[setId] };
    const newSetlist = JSON.parse(JSON.stringify(setlist));

    const newTrackIds = [...newSetlist.trackIds] || [];

    if (newTrackIds.indexOf(trackId) >= 0) {
      newTrackIds.splice(newTrackIds.indexOf(trackId), 1);
    } else {
      newTrackIds.push(trackId);
    }
    newSetlist.trackIds = newTrackIds;
    updateDatabase("sets", newSetlist, setId);
  };

  const updateTrackIds = (value, filteredTracks) => {
    const seqAry = filteredTracks.map(track => track.trackId);

    const trackIds = [...sets[setId].trackIds] || [];
    const newTrackIds = JSON.parse(JSON.stringify(trackIds));

    for (const trackId of seqAry) {
      if (value && newTrackIds.indexOf(trackId) < 0) {
        newTrackIds.push(trackId);
      }
      if (!value && newTrackIds.indexOf(trackId) >= 0) {
        newTrackIds.splice(newTrackIds.indexOf(trackId), 1);
      }
    }

    const newSetlist = { ...sets[setId], trackIds: newTrackIds };
    updateDatabase("sets", newSetlist, setId);
  };

  const pledgeTotal = [...tracksAry, artist.currentTrack]
    .map(t =>
      (t.requestedBy || [])
        .map(r =>
          Number(((isArtistOrAdmin || r.uid === auth.uid) && r.pledge) || 0)
        )
        .reduce((a, b) => Number(a) + Number(b), 0)
    )
    .reduce((a, b) => Number(a) + Number(b), 0);

  const commitedTotal = (artist.pledges || [])
    .map(p =>
      Number(((isArtistOrAdmin || p.uid === auth.uid) && p.pledge) || 0)
    )
    .reduce((a, b) => Number(a) + Number(b), 0);

  const assets = {
    ...props.assets,
    handleModeChange,
    tracksAry,
    playOrderTracks,
    requestableTracks,
    loadNextTrack,
    setCurrentTrack,
    clearCurrentTrack,
    markTrackAndClear,
    updateTrackIds,
    handleIncludeTrack,
    isAdmin,
    isArtist,
    pledgeTotal,
    commitedTotal
  };

  return <ArtistSetLayout assets={assets} />;
};

export default ArtistSet;
